import { ml } from "../../../../core/common/matrixlib";
import { IRiskParameter, RiskCalculator } from "../../../../core/common/UI/Controls/riskCtrl2";
import { IReference } from "../../../../core/globals";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../core/printinterface/PrintProcessorInterfaces";
import { IRiskConfigFactorWeight } from "../../../../core/ProjectSettings";
import { PrintProcessor } from "../../PrintProcessor";
import { IPrintFieldParams } from "../../PrintValueOf";
import { FieldHelper } from "./FieldHelper";
import { FieldRisk } from "./fieldtype/FieldRisk";

export type { IFieldRiskGenericParams };
export { FieldRiskGeneric };

interface IFieldRiskGenericParams extends IPrintFieldParams {
    class: string; // default:"". additional class for outermost container
}

class FieldRiskGeneric implements IPrintFunction {
    static uid = "xxxxxxxx"; // note this is replaced by each of the functions instanciated below
    attribute: string = "xxxxxxxx";
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "xxxxxxxx";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getGroup() {
        return "Item";
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getSubGroup() {
        return "Risk";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp() {
        return `<h1>Risk Attribute Options</h1>
    <p>No options</p>
`;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IFieldRiskGenericParams>paramsIn;
        const defaults: IFieldRiskGenericParams = {
            class: "",
        };

        const params = ml.JSON.clone({
            ...defaults,
            ...overwrites.customer[FieldRisk.uid],
            ...paramsCaller,
            ...overwrites.project[FieldRisk.uid],
            ...overwrites.section[FieldRisk.uid],
        });

        let riskFields = itemOrFolder.children("FIELD[field_type='risk2']").toArray();

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (riskFields.length != 1) {
            // onError( "FieldRiskGeneric called for item with no risk field");
            return "";
        }

        let riskField = riskFields[0];

        if (
            !riskField.childNodes ||
            riskField.childNodes.length < 2 ||
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            (riskField.childNodes.length == 2 && $(riskField.childNodes[1]).text() == "")
        ) {
            return "";
        }

        // get the risk config
        let itemCat = itemOrFolderRef.split("-")[0];
        let riskConfig = globals.riskConfigs[itemCat];
        if (!riskConfig) {
            let category = itemOrFolder.closest("category");

            let fieldId = riskField.getAttribute("field_id");
            let configs = category.children(`field_def[field_id='${fieldId}']`);

            let fieldConfig: IRiskParameter = <IRiskParameter>PrintProcessor.getCdataAsJSON(configs[0]);

            if (fieldConfig.riskConfig) {
                riskConfig = fieldConfig.riskConfig;
            } else {
                // get the global setting
                riskConfig = PrintProcessor.getJsonConfig("risk_config", mf);
            }
            globals.riskConfigs[itemCat] = riskConfig;
        }
        if (!riskConfig || !riskConfig.factors) {
            onError("missing risk configuration");
            return "";
        }
        // figure out what the attribute is
        if (this.attribute.length < 2) {
            onError("bad risk attribute index");
            return "";
        }
        let attributeType = this.attribute[0]; // F a factor,
        // B a weight before controls, A a weight value after controls
        // totalRBM, totalRAM
        let attributeIndex = Number(this.attribute[1]); // the 0, 1,2,3, ... weight, factor, value,

        let attributeFromConfig: string = this.attribute;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (attributeType == "F") {
            if (riskConfig.factors.length <= attributeIndex) {
                onError(`There are no ${attributeIndex} factors in this risk config`);
                return "";
            } else {
                attributeFromConfig = riskConfig.factors[attributeIndex].type;
            }
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
        } else if (attributeType == "B" || attributeType == "A") {
            let weights: IRiskConfigFactorWeight[] = [];
            for (let fac of riskConfig.factors || []) {
                weights = weights.concat(fac.weights);
            }
            if (weights.length <= attributeIndex) {
                onError(`There are no ${attributeIndex} weights in this risk config`);
                return "";
            } else {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                attributeFromConfig = (attributeType == "B" ? "before." : "after.") + weights[attributeIndex].type;
            }
        }

        // get the risk value
        let riskValue = PrintProcessor.getCdataAsJSON(riskField);

        // update the risk
        let links: IReference[] = [];
        $.each(riskValue.mitigations, function (idx, mit) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (possibleTargets.indexOf(mit.to) != -1) {
                links.push({ to: mit.to, title: globals.itemMap[mit.to][0].getAttribute("title") || "" });
            }
        });

        // get the wanted attribute
        let riskCalculator = new RiskCalculator(riskConfig);
        riskCalculator.init(riskValue);
        riskCalculator.updateMitigations(links);
        let html = riskCalculator.getAttributeHTML(attributeFromConfig);

        return FieldHelper.fixTermsAndAbbreviation(html, mf);
    }
}

class FieldRiskGenericF0 extends FieldRiskGeneric {
    static uid = "fieldriskgenericf0";
    attribute: string = "F0";
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Risk Text Description 1";
    }
}
class FieldRiskGenericF1 extends FieldRiskGeneric {
    static uid = "fieldriskgenericf1";
    attribute: string = "F1";
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Risk Text Description 2";
    }
}
class FieldRiskGenericF2 extends FieldRiskGeneric {
    static uid = "fieldriskgenericf2";
    attribute: string = "F2";
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Risk Text Description 3";
    }
}
class FieldRiskGenericF3 extends FieldRiskGeneric {
    static uid = "fieldriskgenericf3";
    attribute: string = "F3";
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Risk Text Description 4";
    }
}
class FieldRiskGenericF4 extends FieldRiskGeneric {
    static uid = "fieldriskgenericf4";
    attribute: string = "F4";
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Risk Text Description 5";
    }
}

class FieldRiskGenericB0 extends FieldRiskGeneric {
    static uid = "fieldriskgenericb0";
    attribute: string = "B0";
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Risk Value Before Mitigation 1";
    }
}
class FieldRiskGenericB1 extends FieldRiskGeneric {
    static uid = "fieldriskgenericb1";
    attribute: string = "B1";
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Risk Value Before Mitigation 2";
    }
}
class FieldRiskGenericB2 extends FieldRiskGeneric {
    static uid = "fieldriskgenericb2";
    attribute: string = "B2";
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Risk Value Before Mitigation 3";
    }
}
class FieldRiskGenericB3 extends FieldRiskGeneric {
    static uid = "fieldriskgenericb3";
    attribute: string = "B3";
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Risk Value Before Mitigation 4";
    }
}
class FieldRiskGenericB4 extends FieldRiskGeneric {
    static uid = "fieldriskgenericb4";
    attribute: string = "B4";
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Risk Value Before Mitigation 5";
    }
}

class FieldRiskGenericA0 extends FieldRiskGeneric {
    static uid = "fieldriskgenerica0";
    attribute: string = "A0";
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Risk Value After Mitigation 1";
    }
}
class FieldRiskGenericA1 extends FieldRiskGeneric {
    static uid = "fieldriskgenerica1";
    attribute: string = "A1";
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Risk Value After Mitigation 2";
    }
}
class FieldRiskGenericA2 extends FieldRiskGeneric {
    static uid = "fieldriskgenerica2";
    attribute: string = "A2";
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Risk Value After Mitigation 3";
    }
}
class FieldRiskGenericA3 extends FieldRiskGeneric {
    static uid = "fieldriskgenerica3";
    attribute: string = "A3";
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Risk Value After Mitigation 4";
    }
}
class FieldRiskGenericA4 extends FieldRiskGeneric {
    static uid = "fieldriskgenerica4";
    attribute: string = "A4";
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Risk Value After Mitigation 5";
    }
}

class FieldRiskGenericTB extends FieldRiskGeneric {
    static uid = "fieldriskgenerictb";
    attribute: string = "totalRBM";
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Risk Total Risk Before Mitigation";
    }
}
class FieldRiskGenericTA extends FieldRiskGeneric {
    static uid = "fieldriskgenericta";
    attribute: string = "totalRAM";
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Risk Total Risk After Mitigation";
    }
}

PrintProcessor.addFunction(FieldRiskGenericF0.uid, new FieldRiskGenericF0());
PrintProcessor.addFunction(FieldRiskGenericF1.uid, new FieldRiskGenericF1());
PrintProcessor.addFunction(FieldRiskGenericF2.uid, new FieldRiskGenericF2());
PrintProcessor.addFunction(FieldRiskGenericF3.uid, new FieldRiskGenericF3());
PrintProcessor.addFunction(FieldRiskGenericF4.uid, new FieldRiskGenericF4());

PrintProcessor.addFunction(FieldRiskGenericB0.uid, new FieldRiskGenericB0());
PrintProcessor.addFunction(FieldRiskGenericB1.uid, new FieldRiskGenericB1());
PrintProcessor.addFunction(FieldRiskGenericB2.uid, new FieldRiskGenericB2());
PrintProcessor.addFunction(FieldRiskGenericB3.uid, new FieldRiskGenericB3());
PrintProcessor.addFunction(FieldRiskGenericB4.uid, new FieldRiskGenericB4());

PrintProcessor.addFunction(FieldRiskGenericA0.uid, new FieldRiskGenericA0());
PrintProcessor.addFunction(FieldRiskGenericA1.uid, new FieldRiskGenericA1());
PrintProcessor.addFunction(FieldRiskGenericA2.uid, new FieldRiskGenericA2());
PrintProcessor.addFunction(FieldRiskGenericA3.uid, new FieldRiskGenericA3());
PrintProcessor.addFunction(FieldRiskGenericA4.uid, new FieldRiskGenericA4());

PrintProcessor.addFunction(FieldRiskGenericTB.uid, new FieldRiskGenericTB());
PrintProcessor.addFunction(FieldRiskGenericTA.uid, new FieldRiskGenericTA());
