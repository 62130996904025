import { ml } from "../../../core/common/matrixlib";
import { IConditionFunction, IGlobalPrintFunctionParams } from "../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../core/printinterface/PrintProcessorInterfaces";
import { ITraceConfig } from "../../../core/ProjectSettings";
import { LinksIterator } from "../iterators/LinksIterator";
import { PrintProcessor } from "../PrintProcessor";

export type { IItemHasTraces };
export { ItemHasTraces };

interface IItemHasTraces {
    iterator: string; // default:"links". Which iterator to use, defaults to standard link iterator
    direction: "up" | "down"; // default:"down". whether to show up or down links
    mustHave: boolean; // default:false. if mustHave is set to true it also returns true if there should be but aren't any traces
    canHave: boolean; // default:false. if canHave is set to true it also returns true if there could be but aren't any traces
    negate: boolean; // negate filter
}

class ItemHasTraces implements IConditionFunction {
    itemOrFolder = true;
    static uid = "itemHasTraces";

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp() {
        return `<h1>ITEM: Render if the item has traces</h1>
<p>Options</p>
<pre>
    iterator:string, // default:"links". Which iterator to use, defaults to standard link iterator
    direction:"up"|"down", // default:"down". whether to check up or down links
    mustHave:boolean // default:false. if mustHave is set to true it also returns true if there should be but aren't any traces
    canHave:boolean // default:false. if canHave is set to true it also returns true if there could be but aren't any traces
    negate:boolean // negate filter
</pre>`;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "ITEM: Render if the item has traces";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async evaluate(
        overwrites: IGlobalPrintFunctionParams,
        paramsCaller: IItemHasTraces,
        itemOrFolderRef: string,
        item: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsDefaults: IItemHasTraces = {
            direction: "down", // default down
            mustHave: false,
            canHave: false,
            iterator: LinksIterator.uid,
            negate: false,
        };

        const params = ml.JSON.clone({
            ...paramsDefaults,
            ...overwrites.customer[ItemHasTraces.uid],
            ...paramsCaller,
            ...overwrites.project[ItemHasTraces.uid],
            ...overwrites.section[ItemHasTraces.uid],
        });

        let linksIterator = PrintProcessor.getItemIterator(params.iterator);

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (linksIterator == null) {
            throw new Error("Unable to get the links iterator");
        }

        let links = await linksIterator.iterate(
            overwrites,
            params,
            itemOrFolderRef,
            mf,
            globals,
            possibleTargets,
            onError,
        );

        if (links.length) {
            // whatever traces it has (required or not required)
            return true;
        }

        // this is a real CAT-ID so below will give the item id
        let category = itemOrFolderRef.split("-")[0];

        let traceJson = <ITraceConfig>PrintProcessor.getJsonConfig("trace_config", mf);
        if (!traceJson) {
            // no trace rules defined
            return false;
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let catRules = traceJson.rules.filter((rule) => rule.category == category);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (catRules.length == 0) {
            // no trace rule for category defined
            return false;
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let rules = params.direction == "down" ? catRules[0].down_rules : catRules[0].up_rules;

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (params.mustHave && rules.filter((rule) => rule.rule == "must_have").length > 0) {
            // there should be at least one trace
            return true;
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (params.canHave && rules.filter((rule) => rule.rule == "can_have").length > 0) {
            // there can be at least one trace
            return true;
        }

        return false;
    }
}

PrintProcessor.addConditionFunction(ItemHasTraces.uid, new ItemHasTraces());
