import { ml } from "../../../../../core/common/matrixlib";
import { IUploadedFileInfo } from "../../../../../core/common/matrixlib/MatrixLibInterfaces";
import { globalMatrix, matrixSession } from "../../../../../core/globals";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../../PrintProcessor";
import { IPrintFieldParams } from "../../../PrintValueOf";

export type { IFieldFileManagerParams };
export { FieldFileManager };

interface IFieldFileManagerParams extends IPrintFieldParams {
    formatFile: "list" | "comma"; // default:"list". list or comma separated, if set to something other than list or comma it is used as a separator string
    asLinkFile: boolean; // default:true, show files as hyperlinks to file
    class: string; // default:"". additional class for outermost container
}

class FieldFileManager implements IPrintFunction {
    static uid = PrintProcessor.getFieldFunctionId("filemanager");

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getGroup() {
        return PrintProcessor.FIELD_FUNCTION_TYPE;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp() {
        return `<h2>file attachment</h2>
<pre>
    formatFile:"list"|"comma" // default:"list". list or comma separated, if set to something other than list or comma it is used as a separator string
    asLinkFile:boolean // default:true, show files with a hyperlinks to download the file
</pre>`;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "file manager field renderer (default implementation)";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IFieldFileManagerParams>paramsIn;
        const defaults: IFieldFileManagerParams = {
            formatFile: "list", // list or comma separated, default list
            asLinkFile: true,
            class: "", // default:"". additional class for outermost container
        };

        const params = ml.JSON.clone({
            ...defaults,
            ...overwrites.customer[FieldFileManager.uid],
            ...paramsCaller,
            ...overwrites.project[FieldFileManager.uid],
            ...overwrites.section[FieldFileManager.uid],
        });

        if (!paramsCaller.fieldInfo || !paramsCaller.fieldInfo.field) {
            onError("called a field rendering function without passing a field");
            return "";
        }

        if (!paramsCaller.fieldInfo.jsonValue) {
            return "";
        }

        let files = paramsCaller.fieldInfo.jsonValue ? <IUploadedFileInfo[]>paramsCaller.fieldInfo.jsonValue : [];

        let rendered = `<div class='${params.class} ${FieldFileManager.uid}'>`;

        let refs: string[] = [];
        $.each(files, function (idx, fileRef) {
            if (params.asLinkFile) {
                refs.push(
                    `<span class="fileLink" ><a href="${
                        globalMatrix.matrixRestUrl + "/" + matrixSession.getProject() + "/file/" + fileRef.fileId
                    }">${fileRef.fileName}</a></span>`,
                );
            } else {
                refs.push(`<span class="fileName">${fileRef.fileName}</span>`);
            }
        });
        rendered +=
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            params.formatFile == "list"
                ? "<ul>" + refs.map((u) => `<li>${u}</li>`).join("") + "</ul>"
                : // TODO: MATRIX-7555: lint errors should be fixed for next line
                  // eslint-disable-next-line
                  refs.join(params.formatFile == "comma" ? ", " : params.formatFile);

        rendered += "</div>";
        return rendered;
    }
}

PrintProcessor.addFunction(FieldFileManager.uid, new FieldFileManager());
