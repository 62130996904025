import { ml } from "../../../../core/common/matrixlib";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../PrintProcessor";
import { IPrintLabelParams } from "../../PrintValueOf";

export type { ILabelPrimitiveParams };
export { LabelIconAndName };

interface ILabelPrimitiveParams {
    showIcon: boolean; // default:true. if set to true shows the icon
    showText: boolean; // default:true. if set to true show the label name
    class: string; // default:"label". class for outermost container
    classIcon: string; // default:"labelIcon". class for icon
    className: string; // default:"labelName". class for name
}

class LabelIconAndName implements IPrintFunction {
    static uid = "label";

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getGroup() {
        return "Label";
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp() {
        return `<h1>Renders a label with icon and name</h1>
<p>Options</p>
<pre>
    showIcon:boolean // default:true. if set to true shows the icon
    showText: boolean  // default:true. if set to true show the label name
    class:string // default:"label". class for outermost container
    classIcon:string // default:"labelIcon". class for icon
    className:string // default:"labelName". class for name
</pre>`;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Label icon and name";
    }

    private defaults: ILabelPrimitiveParams = {
        showIcon: true,
        showText: true,
        class: "label",
        classIcon: "labelIcon",
        className: "labelName",
    };

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IPrintLabelParams>paramsIn;
        if (!paramsCaller.label) {
            onError("called a label rendering function without passing a label");
            return "";
        }

        const params = ml.JSON.clone({
            ...this.defaults,
            ...overwrites.customer[LabelIconAndName.uid],
            ...paramsCaller,
            ...overwrites.project[LabelIconAndName.uid],
            ...overwrites.section[LabelIconAndName.uid],
        });

        let icon = `<span class='${params.classIcon} ${paramsCaller.label.icon}'></span>`;
        let text = `<span class='${params.className}'>${paramsCaller.label.printName}</span>`;
        return `<span class='${params.class}'>${params.showIcon ? icon : ""}${params.showText ? text : ""}</span>`;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    editParams(params: ILabelPrimitiveParams, onUpdate: (newParams: ILabelPrimitiveParams) => void) {
        let ui = $("<div>");

        let org = <ILabelPrimitiveParams>ml.JSON.clone({ ...this.defaults, ...params });

        ml.UI.addCheckbox(ui, "Show icon of label", org, "showIcon", () => {
            onUpdate(org);
        });
        ml.UI.addCheckbox(ui, "Show text of label", org, "showText", () => {
            onUpdate(org);
        });

        return ui;
    }
}

PrintProcessor.addFunction(LabelIconAndName.uid, new LabelIconAndName());
