import { PreProcessor } from "../PrintProcessorRegistry";
import { IPrintFunctionParams } from "../../../core/printinterface/PrintFunction";

interface IRemoveInputParams extends IPrintFunctionParams {
    items?: string[];
}

// example delete some items from tree
export const removeInput: PreProcessor = {
    run: (mf: JQuery, params: IRemoveInputParams) => {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (typeof params["items"] == "undefined") {
            console.log("removeInput - items to remove missing");
            return;
        }

        for (const item of params["items"]) {
            $(`*[ref="${item}"]`, mf).remove();
        }
    },
};
