import { ml } from "../../../../../core/common/matrixlib";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../../PrintProcessor";
import { IPrintFieldParams } from "../../../PrintValueOf";
import { FieldHelper } from "../FieldHelper";

export type { IFieldRichtextParams };
export { FieldRichText };

interface IFieldRichtextParams extends IPrintFieldParams {
    class: string; // default:"". additional class for outermost container
}

class FieldRichText implements IPrintFunction {
    static uid = PrintProcessor.getFieldFunctionId("richtext");

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getGroup() {
        return PrintProcessor.FIELD_FUNCTION_TYPE;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp() {
        return `<h2>Renders a rich text field</h2>
    <p>No options</p>`;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "rich text";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IFieldRichtextParams>paramsIn;
        const defaults: IFieldRichtextParams = {
            class: ``,
        };

        const params = ml.JSON.clone({
            ...defaults,
            ...overwrites.customer[FieldRichText.uid],
            ...paramsCaller,
            ...overwrites.project[FieldRichText.uid],
            ...overwrites.section[FieldRichText.uid],
        });

        if (!paramsCaller.fieldInfo || !paramsCaller.fieldInfo.field) {
            onError("called a field rendering function without passing a field");
            return "";
        }

        let field = paramsCaller.fieldInfo.field;

        //MATRIX-4981 Should be escaped by editor if needed
        let text = field.length ? PrintProcessor.getCdataAsText(field[0], false) : "";

        text = FieldHelper.fixTermsAndAbbreviation(text, mf);

        let rendered = `<div class='${params.class} ${FieldRichText.uid}'>${text}</div>`;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (overwrites.outputFormat == "xlsx") {
            const obj = $(rendered);
            $("div", obj).append("\n");
            $("p", obj).append("\n");
            // MATRIX-5167 enforce an escaping on the content to make sure special symbols are escaped
            const tempDiv = document.createElement("div");
            tempDiv.innerText = obj.text();
            return `<div>${tempDiv.innerHTML}</div>`;
        }
        return rendered;
    }
}

PrintProcessor.addFunction(FieldRichText.uid, new FieldRichText());
