import { ml } from "../../../../../core/common/matrixlib";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../../PrintProcessor";
import { IPrintFieldParams } from "../../../PrintValueOf";
import { FieldFileManager } from "./FieldFileManager";
import { IGateStatus } from "../../../../../core/common/businesslogic/FieldHandlers/GateFieldHandler";
import { IGate } from "../../../../../core/common/UI/Controls/gateControl";

export type { IFieldGateControlParams };
export { FieldGateControl };

interface IFieldGateControlParams extends IPrintFieldParams {
    showStatus: number;
    passedStatus: string;
    failedStatus: string;
    progressStatus: string;

    showLines: number;
    showDate: boolean;
    showUser: boolean;
    showComment: boolean;
    passedLine: string;
    failedLine: string;
    progressLine: string;

    // user details
    login: boolean;
    first: boolean;
    last: boolean;
    email: boolean;

    showHeader: boolean;

    class: string;
}

class FieldGateControl implements IPrintFunction {
    static uid = PrintProcessor.getFieldFunctionId("gatecontrol");

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getGroup() {
        return PrintProcessor.FIELD_FUNCTION_TYPE;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp() {
        return `<h2>gate</h2>
<pre>
    showStatus:number // default:0 - show overall gate status. 0:use gate configuration, 1:force display, -1:hide
    passedStatus:string, // default:"". (overwrites gate configuration)
    failedStatus:string, //  default:"".  (overwrites gate configuration)
    progressStatus:string, //  default:"".  (overwrites gate configuration)

    showLines:number // default:0 - show info for each gate line. 0:use gate configuration, 1:force display, -1:hide
    showDate:boolean // default:true - show the approval/rejection date. for pre 2.2 gates that's the revision date
    showUser:boolean // default:true - show approval/rejection user(s).
    showComment:boolean // default:true - show approval/rejection comment.
    passedLine:string, // default:"". text to display for line status passed (overwrites gate configuration)
    failedLine:string, // default:"". text to display for line status failed (overwrites gate configuration)
    progressLine:string,  // default:"". text to display for line status if neither passed or failed (overwrites gate configuration)
    // user details
    login:boolean // default:false. if set to true show login (user id)
    first:boolean // default:true. if set to true show first name
    last:boolean // default:true. if set to true show last name
    email:boolean // default:false. if set to true show email
</pre>`;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "gate field renderer (default implementation)";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IFieldGateControlParams>paramsIn;
        const defaults: IFieldGateControlParams = {
            showStatus: 0,
            passedStatus: "",
            failedStatus: "",
            progressStatus: "",

            showLines: 0,
            showDate: true,
            showComment: true,
            showUser: true,
            passedLine: "",
            failedLine: "",
            progressLine: "",

            login: false,
            first: true,
            last: true,
            email: false,
            class: "",

            showHeader: false,
        };

        const params = ml.JSON.clone({
            ...defaults,
            ...overwrites.customer[FieldGateControl.uid],
            ...paramsCaller,
            ...overwrites.project[FieldGateControl.uid],
            ...overwrites.section[FieldGateControl.uid],
        });

        if (!paramsCaller.fieldInfo || !paramsCaller.fieldInfo.field) {
            onError("called a field rendering function without passing a field");
            return "";
        }

        let val = <IGateStatus>paramsCaller.fieldInfo.jsonValue;
        let conf = <IGate>paramsCaller.fieldInfo.jsonConfig;

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (!val || JSON.stringify(val) == "{}") {
            return "";
        }

        let rendered = `<div class='${params.class} ${FieldFileManager.uid}'>`;

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (params.showStatus != -1) {
            // show the overall gate status
            let statusText = params.progressStatus ? params.progressStatus : conf.printTodo;
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (params.showStatus == 1 && !statusText) {
                // force a text
                statusText = "in progress";
            }
            let statusStateClass = "Gate in progress";

            if (val.passed) {
                statusStateClass = "passed";
                statusText = params.passedStatus ? params.passedStatus : conf.printAllPassed;
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (params.showStatus == 1 && !statusText) {
                    // force a text
                    statusText = "Gate approved";
                }
            } else if (val.failed) {
                statusStateClass = "failed";
                statusText = params.failedStatus ? params.failedStatus : conf.printNotPassed;
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (params.showStatus == 1 && !statusText) {
                    // force a text
                    statusText = "Gate rejected";
                }
            }

            if (statusText) {
                rendered += `<div class="gateStatus ${statusStateClass}">${statusText}</div>`;
            }
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (params.showLines != -1 && val.lines) {
            rendered += "<table class='gateLineTable'>";
            if (params.showHeader) {
                rendered += "<tr><th class='gateLineStatus'>Info</th>";
                if (params.showComment) {
                    rendered += "<th class='gateLineComment'>Comment</th>";
                }
                rendered += "</tr>";
            }

            for (let lineIdx = 0; lineIdx < val.lines.length; lineIdx++) {
                let line = val.lines[lineIdx];
                let lineConf = conf.lines[lineIdx];
                if (!lineConf) {
                    break;
                }

                let lineStateText = params.progressLine ? params.progressLine : lineConf.hint;
                let lineStateClass = "progress";
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if ((params.showLines == 1 || conf.printSignaturesRequired) && !lineStateText) {
                    // force a text
                    lineStateText = "in progress";
                }
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (params.showLines != 1 || !conf.printSignaturesRequired) {
                    // we don't force a text and the config says not to print this
                    lineStateText = "";
                }

                if (line.passed) {
                    lineStateClass = "passed";
                    lineStateText = params.passedLine ? params.passedLine : lineConf.hintDone;
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if ((params.showLines == 1 || conf.printSignaturesApproved) && !lineStateText) {
                        // force a text
                        lineStateText = "approved by";
                    }
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (params.showLines != 1 && !conf.printSignaturesApproved) {
                        // we don't force a text and the config says not to print this
                        lineStateText = "";
                    }
                } else if (line.failed) {
                    lineStateClass = "failed";
                    lineStateText = params.failedLine ? params.failedLine : lineConf.hintRejected;
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (params.showLines == 1 && conf.printSignaturesRejected && !lineStateText) {
                        // force a text
                        lineStateText = "rejected by";
                    }
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (params.showLines != 1 && !conf.printSignaturesRejected) {
                        // we don't force a text and the config says not to print this
                        lineStateText = "";
                    }
                }
                if (lineStateText) {
                    let users: string[] = [];
                    if (line.passed || line.failed) {
                        users.push(line.user);
                    } else {
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        let lc = conf.lines.filter((cl) => cl.id == line.id);
                        if (lc.length) {
                            users = lc[0].users;
                        }
                    }

                    let userNames = users.map(
                        (user) =>
                            `<span class="gateLineUser">${PrintProcessor.getUserName(
                                user,
                                mf,
                                params.first,
                                params.last,
                                params.login,
                                params.email,
                            )}</span>`,
                    );

                    let lineDate = line.dateUser ? line.dateUser : ""; // itemOrFolder.attr("birth"); // in 2.2 and before the date was not written, take the revision date instead
                    let lineComment = line.comment;
                    rendered += `<tr class="gateLineTableRow"><td class="gateLineStatus ${lineStateClass}"><table class="gateLineRowUserTable"><tr><td class="gateLineText" colspan="2">${lineStateText}</td></tr>`;
                    const showUser = params.showUser && userNames.length;
                    const showDate = params.showDate && lineDate;
                    if (showUser || showDate) {
                        rendered += "<tr>";
                        if (showUser) {
                            rendered += `<td class="gateLineUsers">${userNames.join(", ")}</td>`;
                        }
                        if (showDate) {
                            rendered += `<td class="gateLineDate">${lineDate}</td>`;
                        }
                        rendered += "</tr>";
                    }
                    rendered += "</table></td>";
                    if (params.showComment) {
                        rendered += `<td class="gateLineComment">${lineComment}</td>`;
                    }
                    rendered += `</tr>`;
                }
            }
            rendered += "</table>";
        }

        rendered += "</div>";
        return rendered;
    }
}

PrintProcessor.addFunction(FieldGateControl.uid, new FieldGateControl());
