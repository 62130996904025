import { ml } from "../../../../core/common/matrixlib";
import { IPrintFunction, IGlobalPrintFunctionParams } from "../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../core/printinterface/PrintProcessorInterfaces";
import { ITestConfig, TestResultType } from "../../../../core/ProjectSettings";
import { PrintProcessor } from "../../PrintProcessor";
import { IXTCStatusParamsStates } from "./XTCStatus";

export type { IXTCColorParams };
export { XTCColor };

interface IXTCColorParams {
    colors?: IXTCStatusParamsStates;
    /* default
    {
            error: "red",
            ok: "black",
            warning: "orange",
            new:"blue"
    }
    }*/
}

class XTCColor implements IPrintFunction {
    static uid = "xtccolor";

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getGroup() {
        return "CSS";
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getTemplate() {
        return `color:$['renderFunction':'${XTCColor.uid}','colors':{'error':'red','ok':'black','warning':'orange','new':'blue'}]$`;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp() {
        return `<h1>Renders a color of XTC depending on its result</h1>

<p>Options</p>
<pre>
    colors?: IXTCStatusParamsStates // can be set to define colors,e.g. icons:{error:"red"} // default black
    /* default
    {
            error: "red",
            ok: "black",
            warning: "orange",
            new:"blue"
    }
    }*/
</pre>`;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Colors of an XTC";
    }

    private defaults: IXTCColorParams = {
        colors: {
            error: "red",
            ok: "black",
            warning: "orange",
            new: "blue",
        },
    };

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsCaller: IXTCColorParams,
        itemOrFolderRef: string,
        item: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const params = ml.JSON.clone({
            ...this.defaults,
            ...overwrites.customer[XTCColor.uid],
            ...paramsCaller,
            ...overwrites.project[XTCColor.uid],
            ...overwrites.section[XTCColor.uid],
        });

        let category = item.closest("category").attr("label");
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (category != "XTC") {
            return "";
        }
        for (let field of item.children("FIELD[field_type='test_result']").toArray()) {
            //MATRIX-4981 Should not escape because the content is not unescaped in the filter.xml - did not change
            let result = PrintProcessor.getCdataAsText(field, false);

            let status = this.getStatus(result, mf);
            if (params.colors) {
                return params.colors[status];
            }
        }
        return "";
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected getHuman(result: string, mf: JQuery) {
        let xtcConfig = <ITestConfig>PrintProcessor.getJsonConfig("xtc_config", mf);
        if (!xtcConfig) {
            return "";
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let manu = xtcConfig.manual.filter((mr) => mr.code == result);
        if (manu.length) {
            return manu[0].human;
        }

        let code = result ? result.split("|")[0] : "";

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let auto = xtcConfig.automatic.filter((ar) => ar.code == code);
        if (auto.length) {
            return auto[0].human;
        }

        return "";
    }
    // returns status as new, passed, failed, progress
    protected getStatus(result: string, mf: JQuery): string | TestResultType {
        let xtcConfig = <ITestConfig>PrintProcessor.getJsonConfig("xtc_config", mf);
        if (!xtcConfig) {
            return "";
        }
        // see getSearchExpression in TestManager
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (result == xtcConfig.defaultTestResultResult) {
            return "new";
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (result == "r") {
            return "new";
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (result == "i") {
            return "warning";
        }
        if (xtcConfig.automatic.length) {
            let autoProgress = xtcConfig.automatic[xtcConfig.automatic.length - 1];
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (result == autoProgress.code + "|" + autoProgress.render + "|" + autoProgress.human) {
                return "warning";
            }
        }
        for (const progress of xtcConfig.automatic) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (result == progress.code + "|" + progress.render + "|" + progress.human) {
                return progress.render;
            }
        }
        for (const progress of xtcConfig.manual) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (result == progress.code) {
                return progress.render;
            }
        }
        return "";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    editParams(params: IXTCColorParams, onUpdate: (newParams: IXTCColorParams) => void) {
        let ui = $("<div>");

        // here we need a ...{}, as first parameter to make a new copy
        let p = ml.JSON.clone({ ...{}, ...this.defaults, ...params });

        ml.UI.addTextInput(
            ui,
            "color error",
            p.colors,
            "error",
            () => {
                onUpdate(p);
            },
            () => {},
            false,
        );
        ml.UI.addTextInput(
            ui,
            "color new",
            p.colors,
            "new",
            () => {
                onUpdate(p);
            },
            () => {},
            false,
        );
        ml.UI.addTextInput(
            ui,
            "color ok",
            p.colors,
            "ok",
            () => {
                onUpdate(p);
            },
            () => {},
            false,
        );
        ml.UI.addTextInput(
            ui,
            "color warning",
            p.colors,
            "warning",
            () => {
                onUpdate(p);
            },
            () => {},
            false,
        );

        return ui;
    }
}

PrintProcessor.addFunction(XTCColor.uid, new XTCColor());
