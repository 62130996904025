import { PrintProcessorRegistry } from "./PrintProcessorRegistry";
import { mergeColumns } from "./postProcessors/mergeColumns";
import { deleteColumn } from "./postProcessors/deleteColumn";
import { removeInput } from "./preProcessors/removeInput";
import { removeEmptyRows } from "./postProcessors/removeEmptyRows";
import { replaceCells } from "./postProcessors/replaceCells";

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
export function registerProcessors(registry: PrintProcessorRegistry) {
    registry.registerPostProcessor("mergeColumns", mergeColumns);
    registry.registerPostProcessor("deleteColumn", deleteColumn);
    registry.registerPostProcessor("removeEmptyRows", removeEmptyRows);
    registry.registerPostProcessor("replaceCells", replaceCells);

    registry.registerPreProcessor("removeInput", removeInput);
}
