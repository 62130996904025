import { ml } from "../../../../../core/common/matrixlib";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../../PrintProcessor";
import { IPrintFieldParams } from "../../../PrintValueOf";

export type { IFieldTHyperlinkParams };
export { FieldHyperlink };

interface IFieldTHyperlinkParams extends IPrintFieldParams {
    class: string; // default:"". additional class for outermost container
}

class FieldHyperlink implements IPrintFunction {
    static uid = PrintProcessor.getFieldFunctionId("hyperlink");

    getGroup(): string {
        return PrintProcessor.FIELD_FUNCTION_TYPE;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp():string {
        return `<h2>hyperlink</h2>
    <p>No options</p>`;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName():string {
        return "Textline field renderer (default implementation)n";
    }

    // eslint-disable-next-line max-params
    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ): Promise<string> {
        const paramsCaller = <IFieldTHyperlinkParams>paramsIn;
        const defaults: IFieldTHyperlinkParams = {
            class: ``,
        };

        const params = ml.JSON.clone({
            ...defaults,
            ...overwrites.customer[FieldHyperlink.uid],
            ...paramsCaller,
            ...overwrites.project[FieldHyperlink.uid],
            ...overwrites.section[FieldHyperlink.uid],
        });

        if (!paramsCaller.fieldInfo || !paramsCaller.fieldInfo.field) {
            onError("called a field rendering function without passing a field");
            return "";
        }

        let field = paramsCaller.fieldInfo.field;

        //MATRIX-4981 These are unescaped in the filter.xml and need to be escaped for printing
        let text = field.length ? PrintProcessor.getCdataAsText(field[0], true) : "";

        let rendered = `<a class='${params.class} ${FieldHyperlink.uid}' href="${text}">${text}</a>`;

        return rendered;
    }
}

PrintProcessor.addFunction(FieldHyperlink.uid, new FieldHyperlink());
