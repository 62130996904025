/// <reference types="matrixrequirements-type-declarations" />
import { ml } from "../../../../core/common/matrixlib";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../PrintProcessor";

export type { IItemDateParams };
export { ItemDate };

interface IItemDateParams {
    class: string; // default:"". additional class for outermost container
    format: string; // date format string (default: "" => customer defined format)
}

class ItemDate implements IPrintFunction {
    static uid = "date";

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getGroup() {
        return "Item";
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp() {
        return `<h1>Renders the date and time of a revision of an item in customer format</h1>
<p>Options</p>
<pre>
    format:string // date format string (default: "" => customer defined format)
    class:string // default:"". additional class for outermost container
</pre>`;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Last change date and time of item";
    }

    private defaults: IItemDateParams = {
        format: "",
        class: "",
    };

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IItemDateParams>paramsIn;
        const params = ml.JSON.clone({
            ...this.defaults,
            ...overwrites.customer[ItemDate.uid],
            ...paramsCaller,
            ...overwrites.project[ItemDate.uid],
            ...overwrites.section[ItemDate.uid],
        });

        let dateStr = itemOrFolder.attr("birth_customer_format");
        if (params.format) {
            let date = new Date(itemOrFolder.attr("birth"));

            dateStr = new SimpleDateFormat(params.format).format(date);
        }

        return `<span class="${params.class} ${ItemDate.uid}">${dateStr}</span>`;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    editParams(params: IItemDateParams, onUpdate: (newParams: IItemDateParams) => void) {
        let ui = $("<div>");

        let org = <IItemDateParams>ml.JSON.clone({ ...this.defaults, ...params });

        ml.UI.addTextInput(ui, "Custom format (optional), e.g. yyyy-MM-dd hh:mm", org, "format", () => {
            onUpdate(org);
        });

        return ui;
    }
}

PrintProcessor.addFunction(ItemDate.uid, new ItemDate());
