import { ml } from "../../../../core/common/matrixlib";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../PrintProcessor";

export type { IRevisionPrimitiveParams };
export { RevisionPrimitive };

interface IRevisionPrimitiveParams {
    class: string; // default:"revision". class for outermost container
}

class RevisionPrimitive implements IPrintFunction {
    static uid = "revision";

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getGroup() {
        return "Item";
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp() {
        return `<h1>Renders the current revision number of an item</h1>
<p>No options</p>
<pre>
    class:string // default:"revision". class for outermost container
</pre>`;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Last revision number of item";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IRevisionPrimitiveParams>paramsIn;
        const defaults: IRevisionPrimitiveParams = {
            class: "revision",
        };

        const params = ml.JSON.clone({
            ...defaults,
            ...overwrites.customer[RevisionPrimitive.uid],
            ...paramsCaller,
            ...overwrites.project[RevisionPrimitive.uid],
            ...overwrites.section[RevisionPrimitive.uid],
        });

        return `<span class='${params.class}'>${itemOrFolder.attr("version")}</span>`;
    }
}

PrintProcessor.addFunction(RevisionPrimitive.uid, new RevisionPrimitive());
