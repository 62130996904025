import { ml } from "../../../../../core/common/matrixlib";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../../PrintProcessor";
import { IPrintFieldParams } from "../../../PrintValueOf";
import { XTCStatus } from "../../items/XTCStatus";

export type { IFieldtest_resultParams };
export { FieldTest_result };

interface IFieldtest_resultParams extends IPrintFieldParams {
    class: string; // default:"". additional class for outermost container
}

class FieldTest_result implements IPrintFunction {
    static uid = PrintProcessor.getFieldFunctionId("test_result");

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getGroup() {
        return PrintProcessor.FIELD_FUNCTION_TYPE;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp() {
        return `<h2>test result</h2>
<p>No options</p>`;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "test result field renderer (default implementation)";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IFieldtest_resultParams>paramsIn;
        const defaults: IFieldtest_resultParams = {
            class: ``,
        };

        const params = ml.JSON.clone({
            ...defaults,
            ...overwrites.customer[FieldTest_result.uid],
            ...paramsCaller,
            ...overwrites.project[FieldTest_result.uid],
            ...overwrites.section[FieldTest_result.uid],
        });

        if (!paramsCaller.fieldInfo || !paramsCaller.fieldInfo.field) {
            onError("called a field rendering function without passing a field");
            return "";
        }

        let field = paramsCaller.fieldInfo.field;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (field.length == 0 || !field[0].childNodes || field[0].childNodes.length != 1) {
            return "";
        }
        let rendered = `<span class='${params.class} ${FieldTest_result.uid}'>`;

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (paramsCaller.fieldInfo.field[0].childNodes[0].nodeName == "#cdata-section") {
            const renderFunction = PrintProcessor.getFunction(XTCStatus.uid);
            if (renderFunction) {
                rendered += await renderFunction.renderAsync(
                    overwrites,
                    paramsCaller,
                    itemOrFolderRef,
                    itemOrFolder,
                    mf,
                    globals,
                    possibleTargets,
                    onError,
                );
            }
        }
        rendered += "</span>";
        return rendered;
    }
}

PrintProcessor.addFunction(FieldTest_result.uid, new FieldTest_result());
