import { ml } from "../../../../core/common/matrixlib";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../core/printinterface/PrintProcessorInterfaces";
import { ITestConfig, TestResultType } from "../../../../core/ProjectSettings";
import { PrintProcessor } from "../../PrintProcessor";

export type { IXTCStatusParams, IXTCStatusParamsStates };
export { XTCStatus };

interface IXTCStatusParams {
    showIcons?: boolean; // default:true. if set to true renders icons
    icons?: IXTCStatusParamsStates; // can be used to overwrite default icons
    /* default
    {
            error: "fal fa-bug",
            ok: "fal fa-check",
            warning: "fal fa-exclamation-triangle",
            new:"fal fa-star"
    }
    }*/
    colors?: IXTCStatusParamsStates; // can be set to define colors,e.g. icons:{error:"red"} // default black
    /* default
    {
            error: "",
            ok: "",
            warning: "",
            new:""
    }
    }*/
    raw?: boolean; // default:false. if set to true will return the internal value
    class: string; // default: "xtcstatus". class for outermost container
}

interface IXTCStatusParamsStates {
    error: string; // icon/color in case status is error
    ok: string; // icon/color in case status is passed
    warning: string; // icon/color in case status is in progress
    new: string; // icon/color in case status is new
    [key: string]: string;
}

class XTCStatus implements IPrintFunction {
    static uid = "xtcstatus";

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getGroup() {
        return "Item";
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getSubGroup() {
        return "XTC";
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp() {
        return `<h1>Renders status of XTC</h1>
<p>Options</p>
<pre>
    showIcons?:boolean // default:true. if set to true renders icons
    icons?: IXTCStatusParamsStates // can be used to overwrite default icons
    /* default
    {
            error: "fal fa-bug",
            ok: "fal fa-check",
            warning: "fal fa-exclamation-triangle",
            new:"fal fa-star"
    }
    }*/
    colors?: IXTCStatusParamsStates // can be set to define colors,e.g. icons:{error:"red"} // default black
    /* default
    {
            error: "black",
            ok: "black",
            warning: "black",
            new:"black"
    }
    }*/
    raw?: boolean // default:false. if set to true will return the internal value
    class:string // default: "xtcstatus". class for outermost container
</pre>`;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Status of an XTC";
    }

    private defaults: IXTCStatusParams = {
        icons: {
            error: "fal fa-bug",
            ok: "fal fa-check",
            warning: "fal fa-exclamation-triangle",
            new: "fal fa-star",
        },
        showIcons: true,
        colors: {
            error: "",
            ok: "",
            warning: "",
            new: "",
        },
        class: "xtcstatus",
    };

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsCaller: IPrintFunctionParams,
        itemOrFolderRef: string,
        item: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const params = ml.JSON.clone({
            ...this.defaults,
            ...overwrites.customer[XTCStatus.uid],
            ...(<IXTCStatusParams>paramsCaller),
            ...overwrites.project[XTCStatus.uid],
            ...overwrites.section[XTCStatus.uid],
        });

        let category = item.closest("category").attr("label");
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (category != "XTC") {
            return "";
        }
        for (let field of item.children("FIELD[field_type='test_result']").toArray()) {
            //MATRIX-4981 Should not escape because the content is not unescaped in the filter.xml - did not change
            let result = PrintProcessor.getCdataAsText(field, false);
            if (params.raw) {
                return result;
            }
            let status = this.getStatus(result, mf);
            let colorStart = "";
            let colorEnd = "";

            if (params.colors) {
                if (params.colors[status]) {
                    colorStart = `<span style="color:${params.colors[status]}">`;
                    colorEnd = "</span>";
                }
            }
            let icon = "";
            if (params.showIcons) {
                if (params.icons && params.icons[status]) {
                    icon = `<span class="${params.icons[status]}" ></span> `;
                }
            }

            return `<span class='${params.class} status_${status}'>${colorStart}${icon}${this.getHuman(
                result,
                mf,
            )}${colorEnd}</span> `;
        }
        return "";
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected getHuman(result: string, mf: JQuery) {
        let xtcConfig = <ITestConfig>PrintProcessor.getJsonConfig("xtc_config", mf);
        if (!xtcConfig) {
            return "";
        }
        let manu = xtcConfig.manual.filter((mr) => {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            return mr.code == result || result == mr.code + "|" + mr.render + "|" + mr.human;
        });
        if (manu.length) {
            return manu[0].human;
        }

        let code = result ? result.split("|")[0] : "";

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let auto = xtcConfig.automatic.filter((ar) => ar.code == code);
        if (auto.length) {
            return auto[0].human;
        }

        return "";
    }
    // returns status as new, passed, failed, progress
    protected getStatus(result: string, mf: JQuery): string | TestResultType {
        let xtcConfig = <ITestConfig>PrintProcessor.getJsonConfig("xtc_config", mf);
        if (!xtcConfig) {
            return "";
        }
        // see getSearchExpression in TestManager
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (result == xtcConfig.defaultTestResultResult) {
            return "new";
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (result == "r") {
            return "new";
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (result == "i") {
            return "warning";
        }
        if (xtcConfig.automatic.length) {
            let autoProgress = xtcConfig.automatic[xtcConfig.automatic.length - 1];
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (result == autoProgress.code + "|" + autoProgress.render + "|" + autoProgress.human) {
                return "warning";
            }
        }
        for (const progress of xtcConfig.automatic) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (result == progress.code + "|" + progress.render + "|" + progress.human) {
                return progress.render;
            }
        }
        for (const progress of xtcConfig.manual) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (result == progress.code || result == progress.code + "|" + progress.render + "|" + progress.human) {
                return progress.render;
            }
        }
        return "";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    editParams(params: IXTCStatusParams, onUpdate: (newParams: IXTCStatusParams) => void) {
        let ui = $("<div>");

        let org = ml.JSON.clone({ ...{}, ...this.defaults, ...params });

        const colors = org.colors ?? {};
        const icons = org.icons ?? {};

        ml.UI.addTextInput(
            ui,
            "color error",
            colors,
            "error",
            () => {
                onUpdate(org);
            },
            () => {},
            false,
        );
        ml.UI.addTextInput(
            ui,
            "color new",
            colors,
            "new",
            () => {
                onUpdate(org);
            },
            () => {},
            false,
        );
        ml.UI.addTextInput(
            ui,
            "color ok",
            colors,
            "ok",
            () => {
                onUpdate(org);
            },
            () => {},
            false,
        );
        ml.UI.addTextInput(
            ui,
            "color warning",
            colors,
            "warning",
            () => {
                onUpdate(org);
            },
            () => {},
            false,
        );
        ml.UI.addCheckbox(ui, "show icon", org, "showIcons", () => {
            onUpdate(org);
        });
        ml.UI.addIconInput(
            ui,
            "icon error",
            icons,
            "error",
            () => {
                onUpdate(org);
            },
            () => {},
            false,
        );
        ml.UI.addIconInput(
            ui,
            "icon new",
            icons,
            "new",
            () => {
                onUpdate(org);
            },
            () => {},
            false,
        );
        ml.UI.addIconInput(
            ui,
            "icon ok",
            icons,
            "ok",
            () => {
                onUpdate(org);
            },
            () => {},
            false,
        );
        ml.UI.addIconInput(
            ui,
            "icon warning",
            icons,
            "warning",
            () => {
                onUpdate(org);
            },
            () => {},
            false,
        );

        return ui;
    }
}

PrintProcessor.addFunction(XTCStatus.uid, new XTCStatus());
