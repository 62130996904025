import { ml } from "../../../core/common/matrixlib";
import { IGlobalPrintFunctionParams } from "../../../core/printinterface/PrintFunction";
import { IPrintItemIteratorParams, IPrintItemIterator } from "../../../core/printinterface/PrintIterators";
import { IPrintGlobals } from "../../../core/printinterface/PrintProcessorInterfaces";
import { IPrintSortParams } from "../../../core/printinterface/PrintSorter";
import { PrintProcessor } from "../PrintProcessor";

export type { IChildrenIteratorParams };
export { ChildrenIterator };

interface IChildrenIteratorParams extends IPrintItemIteratorParams {
    leafs?: boolean; // default:false. if set to true the iterator returns not the direct children of a folder but all items inside
    maxDepth?: number; // default:0. (only when getting leafs) maximum depth to look for children. if 0 all children will be returned
    includeItems?: boolean; // default:true includes items in result
    includeFolders?: boolean; // default:true includes folders in result
}
class ChildrenIterator implements IPrintItemIterator {
    worksOnItem = false;
    worksOnFolder = true;
    folderIterator = true;
    traceIterator = false;
    tableRowIterator = false;

    static uid = "children";

    private paramsDefault: IChildrenIteratorParams = {
        includeItems: true,
        includeFolders: true,
    };

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp() {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let sorters = PrintProcessor.getItemSorterDropdown().filter((itdd) => itdd.id != "");
        let sortersText = sorters.map((itdd) => `        //${itdd.id}: ${itdd.label}`).join("\n");

        return `<h1>Iterator over children of a folder</h1>
<p>Options</p>
<pre>
    leafs?:boolean // default:false. if set to true the iterator returns not the direct children of a folder but all items inside
    maxDepth?:number // default:0. (only when getting leafs) maximum depth to look for children. if 0 all children will be returned,
    includeItems?:boolean // default:true includes items in result
    includeFolders?:boolean // default:true includes folders in result
    sorting?: [{ // can be used to sort items other than natural order in tree (note there can be multiple, first applied first)
        descending:boolean // default:false. if true will inverse sort order
        sorter:string // default:"". if specified will sort items. id of sorter can be:
${sortersText}
    }]
</pre>`;
    }

    getValidation(): null {
        return null;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Children of folder";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async iterate(
        overwrites: IGlobalPrintFunctionParams,
        paramsCaller: IChildrenIteratorParams,
        itemOrFolder: string,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (itemOrFolder.indexOf("F-") != 0) {
            onError(`this needs to be a folder id: "${itemOrFolder}"`);
            return [];
        }
        const folder = globals.itemMap[itemOrFolder];
        if (!folder) {
            onError(`cannot iterate over children of unknown folder "${itemOrFolder}"`);
            return [];
        }

        const params = ml.JSON.clone({
            ...this.paramsDefault,
            ...overwrites.customer[ChildrenIterator.uid],
            ...paramsCaller,
            ...overwrites.project[ChildrenIterator.uid],
            ...overwrites.section[ChildrenIterator.uid],
        });

        let children: string[] = [];
        if (params.leafs) {
            this.getChildrenRec(children, 0, params, itemOrFolder, mf, globals, possibleTargets, onError);
        } else {
            $.each(folder.children("ITEM,FOLDER"), function (idx, child) {
                let ref = child.getAttribute("ref");
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (ref.indexOf("F-") == 0) {
                    if (params.includeFolders) {
                        children.push(ref);
                    }
                } else if (params.includeItems) {
                    children.push(ref);
                }
            });
        }

        return children;
    }

    // get all the leafs (items) of a folder
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private getChildrenRec(
        children: string[],
        depth: number,
        params: IChildrenIteratorParams,
        itemOrFolder: string,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        let that = this;
        if (params.maxDepth && depth >= params.maxDepth) {
            return;
        }
        const folder = globals.itemMap[itemOrFolder];
        $.each(folder.children("ITEM,FOLDER"), function (idx, child) {
            let childId = child.getAttribute("ref");
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (childId.indexOf("F-") == 0) {
                // get next level
                that.getChildrenRec(children, depth + 1, params, childId, mf, globals, possibleTargets, onError);
            } else {
                children.push(childId);
            }
        });
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    editParams(params: IChildrenIteratorParams, onUpdate: (newParams: IChildrenIteratorParams) => void) {
        let ui = $("<div>");

        let org = <IChildrenIteratorParams>ml.JSON.clone({ ...{}, ...this.paramsDefault, ...params });

        ml.UI.addCheckbox(ui, "leafs only", org, "leafs", () => {
            onUpdate(org);
        });
        ml.UI.addDropdownNumber(ui, "maximum depth of children", org, "maxDepth", 0, 20, () => {
            onUpdate(org);
        });

        ml.UI.addCheckbox(ui, "includes items in result", org, "includeItems", () => {
            onUpdate(org);
        });
        ml.UI.addCheckbox(ui, "includes folders in result", org, "includeFolders", () => {
            onUpdate(org);
        });

        let sortParams: IPrintSortParams =
            org.sorting && org.sorting.length ? org.sorting[0] : { sorter: "", descending: false };
        let sorters = PrintProcessor.getItemSorterDropdown();
        ml.UI.addDropdownToValue(
            ui,
            "sorting: method",
            sortParams,
            "sorter",
            sorters,
            false,
            false,
            () => {
                org.sorting && org.sorting.length ? (org.sorting[0] = sortParams) : (org.sorting = [sortParams]);
                onUpdate(org);
            },
            "no limit",
            undefined,
        );
        ml.UI.addCheckbox(ui, "sorting: reverse sort order", sortParams, "descending", () => {
            org.sorting && org.sorting.length ? (org.sorting[0] = sortParams) : (org.sorting = [sortParams]);
            onUpdate(org);
        });

        return ui;
    }
}

PrintProcessor.addItemIterator(ChildrenIterator.uid, new ChildrenIterator());
