import { ml } from "../../../../core/common/matrixlib";
import { IPrintFunction, IGlobalPrintFunctionParams } from "../../../../core/printinterface/PrintFunction";
import { IPrintGlobals, IPrintProcessor } from "../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../PrintProcessor";
import { IAttributePrimitiveParams } from "./AttributePrimitive";

export type {
    ITableSummaryParams,
    ITableElement,
    TableElementColumn,
    ITableElementNumeric,
    ITableElementString,
    ITableElementSpan,
};

export { TableSummary };
export { ITableSummaryType, ITableSummaryRounding };
export { ITableElementType };

enum ITableSummaryType {
    Count = "Count",
    Sum = "Sum",
    Average = "Average",
    Median = "Median",
    Minimum = "Minimum",
    Maximum = "Maximum",
}

enum ITableSummaryRounding {
    NoRounding = "No Rounding",
    NoDecimals = "No Decimals",
    One = "One",
    Two = "Two",
    Three = "Three",
    Four = "Four",
    Five = "Five",
    Six = "Six",
}

interface ITableSummaryParams {
    /**
     * The category of table to render
     */
    tableCat?: string;
    /**
     * The serial of table to render
     */
    tableId?: string;
    /**
     * The type of summary to perform
     */
    summary?: ITableSummaryType;
    /**
     * The column to summarize
     */
    column?: number;
    /**
     * Round to how many digits
     */
    rounding?: ITableSummaryRounding;
}

enum ITableElementType {
    Span,
    Number,
    String,
}

type ITableElement = ITableElementSpan | ITableElementNumeric | ITableElementString;
type TableElementColumn = ITableElement[];

interface ITableElementNumeric {
    type: ITableElementType.Number;
    value: number;
}

interface ITableElementString {
    type: ITableElementType.String;
    value: string;
}

interface ITableElementSpan {
    type: ITableElementType.Span;
}

class TableSummary implements IPrintFunction {
    static uid = "tablesummary";
    static defaults: ITableSummaryParams = {
        summary: ITableSummaryType.Count,
        rounding: ITableSummaryRounding.NoRounding,
    };

    static explanation = `Build summary information of a TABLE by choosing a column and then returning the count, sum,
        average, or median of the column. For anything but count the values of the table column have to be numeric and
        will be skipped if they are not. The table ID has to be in the form TABLE-1 and the column count starts at 1.`;

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getGroup() {
        return "Item";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp(_hideDetails?: boolean, _showFieldName?: boolean) {
        return `
            <h1>Creates a summary of a table</h1>
            <p>${TableSummary.explanation}</p>
            <p>Options</p>
            <ul>
                <li><b>summary</b>: The summary type to calculate</li>
                <li><b>tableCat</b>: The category of the table</li>
                <li><b>tableId</b>: The serial of the table</li>
                <li><b>column</b>: The column of the table, starting at 1</li>
                <li><b>rounding</b>: The rounding mode (decimal places): None, Zero, One, Two, Three, Four, Five, Six</li>
            </ul>
        `;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Render summary of a table";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    editParams(params: IAttributePrimitiveParams, onUpdate: (newParams: IAttributePrimitiveParams) => void) {
        const json = ml.JSON.clone({ ...{}, ...TableSummary.defaults, ...params });
        const types = Object.keys(ITableSummaryType).map((key) => ({ label: key, id: key }));
        const roundingTypes = Object.keys(ITableSummaryRounding)
            // @ts-ignore TODO: weird hackery. refactor it
            .map((key) => ({ label: ITableSummaryRounding[key], id: ITableSummaryRounding[key] }));

        if (json.tableId && json.tableCat) {
            json.table = json.tableCat + "-" + json.tableId;
        } else {
            json.table = "";
        }

        // The UI is using a joint string which does not work on the saved data
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        function update(json: any) {
            delete json.table;
            onUpdate(json);
        }

        let uiEdit = $("<div>");
        uiEdit.append($(`<div>${TableSummary.explanation}</div>`));
        ml.UI.addTextInput(
            uiEdit,
            "Table ID",
            json,
            "table",
            () => {
                json.tableCat = "";
                json.tableId = "";
                if (json.table) {
                    const tableSplit = json.table.trim().split("-");
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (tableSplit.length == 2) {
                        json.tableCat = tableSplit[0];
                        json.tableId = tableSplit[1];
                    }
                }
                update(json);
            },
            () => {},
            false,
        );

        ml.UI.addDropdownToValue(uiEdit, "Summary Type", json, "summary", types, true, false, () => {
            update(json);
        });

        ml.UI.addTextInput(
            uiEdit,
            "Column ID",
            json,
            "column",
            () => {
                update(json);
            },
            () => {},
            false,
        );

        ml.UI.addDropdownToValue(
            uiEdit,
            "Rounding Mode (decimal places)",
            json,
            "rounding",
            roundingTypes,
            true,
            false,
            () => {
                update(json);
            },
        );

        return uiEdit;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsCaller: ITableSummaryParams,
        itemOrFolderRef: string,
        item: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
        printProcessor?: IPrintProcessor,
    ) {
        if (!printProcessor) {
            onError("Internal error, print processor not provided");
            return "";
        }

        if (!paramsCaller.tableCat || !paramsCaller.tableId || !paramsCaller.column) {
            onError("The table summary needs the table ID and column to work");
            return "";
        }

        const tableName = paramsCaller.tableCat!.toUpperCase() + "-" + paramsCaller.tableId;
        const column = Number(paramsCaller.column);

        if (!Number.isInteger(column)) {
            onError("The column selection has to be a number");
            return "";
        }

        if (column < 1) {
            onError("First column is 1");
            return "";
        }

        const params = <ITableSummaryParams>ml.JSON.clone({
            ...TableSummary.defaults,
            ...overwrites.customer[TableSummary.uid],
            ...paramsCaller,
            ...overwrites.project[TableSummary.uid],
            ...overwrites.section[TableSummary.uid],
        });

        let ids: string[] = jQuery.map(item, (item, _) => $(item).attr("ref"));
        const html = await printProcessor!.getTableData(tableName, ids);
        const cleanTable = this.normalizeTable(html);

        const colCount = cleanTable.length;
        if (column > colCount) {
            onError(`The table only has ${colCount} columns, you specified ${column}`);
            return "";
        }

        const col = cleanTable[column - 1];
        if (!col) {
            onError("Column is not available");
            return "";
        }
        let allNumeric = true;
        for (let it = 0; it < col.length; it++) {
            if (col[it].type !== ITableElementType.Number) {
                allNumeric = false;
                break;
            }
        }

        return this.summarize(col, params.summary!, params.rounding!, onError);
    }

    summarize(
        data: ITableElement[],
        type: ITableSummaryType,
        rounding: ITableSummaryRounding,
        onError: (message: string) => void,
    ): string {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        const isNumeric = (row: ITableElement) =>
            row.type === ITableElementType.Number || row.type === ITableElementType.Span;
        const allNumeric = data.filter((row) => !isNumeric(row)).length === 0;
        if (type !== ITableSummaryType.Count && !allNumeric) {
            onError("Some of the given data is not numeric");
            return "Invalid Data";
        }

        try {
            switch (type) {
                case ITableSummaryType.Count: {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    const withoutSpans = data.filter((row) => row.type != ITableElementType.Span);
                    return `${withoutSpans.length}`;
                }
                case ITableSummaryType.Sum: {
                    const sum = data.reduce((acc, row) => {
                        switch (row.type) {
                            case ITableElementType.Number:
                                return acc + row.value;
                            case ITableElementType.String:
                                console.log(`Trying to sum a column with text ${row.value}`);
                                throw "Should not reach this point";
                            case ITableElementType.Span:
                                return acc;
                        }
                    }, 0);
                    return `${sum}`;
                }
                case ITableSummaryType.Average: {
                    const avg = data.reduce(
                        (acc, row) => {
                            switch (row.type) {
                                case ITableElementType.Number:
                                    return {
                                        value: acc.value + row.value,
                                        count: acc.count + 1,
                                    };
                                case ITableElementType.String:
                                    onError("Trying to sum a column with text");
                                    throw "Should not reach this point";
                                case ITableElementType.Span:
                                    return acc;
                            }
                        },
                        { value: 0, count: 0 },
                    );
                    const average = avg.value / avg.count;
                    return `${this.round(average, rounding)}`;
                }
                case ITableSummaryType.Median: {
                    // @ts-expect-error I don't feel comfortable updating the logic,
                    // but either isNumeric function is doing the wrong thing, or its name is confusing or
                    // ITableElementSpan interface is missing value property
                    const values = data.filter(isNumeric).map((row: ITableElementNumeric) => row.value);
                    const median = this.median(values);
                    return `${this.round(median, rounding)}`;
                }
                case ITableSummaryType.Maximum: {
                    const initialMax = Number.NEGATIVE_INFINITY;
                    // @ts-expect-error I don't feel comfortable updating the logic,
                    // but either isNumeric function is doing the wrong thing, or its name is confusing or
                    // ITableElementSpan interface is missing value property
                    const maximum: number = data.filter(isNumeric).reduce((acc: number, row: ITableElementNumeric) => {
                        return Math.max(acc, row.value);
                    }, initialMax);
                    return `${maximum > initialMax ? maximum : ""}`;
                }
                case ITableSummaryType.Minimum: {
                    const initialMin = Number.POSITIVE_INFINITY;
                    // @ts-expect-error I don't feel comfortable updating the logic,
                    // but either isNumeric function is doing the wrong thing, or its name is confusing or
                    // ITableElementSpan interface is missing value property
                    const minimum: number = data.filter(isNumeric).reduce((acc: number, row: ITableElementNumeric) => {
                        return Math.min(acc, row.value);
                    }, initialMin);
                    return `${minimum < initialMin ? minimum : ""}`;
                }
            }
        } catch (e) {
            onError("Error trying to summarize tables, maybe the column contains non-numeric data?");
            return "";
        }
    }

    round(value: number, rounding: ITableSummaryRounding): number {
        let decimalsMulti = 1;
        switch (rounding) {
            case ITableSummaryRounding.NoRounding:
                return value;
            case ITableSummaryRounding.NoDecimals:
                decimalsMulti = 1;
                break;
            case ITableSummaryRounding.One:
                decimalsMulti = 10;
                break;
            case ITableSummaryRounding.Two:
                decimalsMulti = 100;
                break;
            case ITableSummaryRounding.Three:
                decimalsMulti = 1000;
                break;
            case ITableSummaryRounding.Four:
                decimalsMulti = 10000;
                break;
            case ITableSummaryRounding.Five:
                decimalsMulti = 100000;
                break;
            case ITableSummaryRounding.Six:
                decimalsMulti = 1000000;
                break;
        }
        // https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
        return Math.round((value + Number.EPSILON) * decimalsMulti) / decimalsMulti;
    }

    normalizeTable(tableString: string): ITableElement[][] {
        const table = $(tableString)[0];
        const rows = $(table).find("tr").toArray();
        rows.shift(); // Get rid of header
        const rowTDs = rows.map((r) => $(r).find("td").toArray());
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (rowTDs.length == 0) {
            return [];
        }
        const colCount = rowTDs[0].length;
        const spans: number[] = [];
        const result: TableElementColumn[] = [];
        for (let it = 0; it < colCount; it++) {
            spans[it] = 0;
            result[it] = [];
        }
        for (let rIt = 0; rIt < rowTDs.length; rIt++) {
            let spanPad = 0;
            for (let it = 0; it < colCount; it++) {
                let currentCell: ITableElement = { type: ITableElementType.Span };
                if (spans[it] > 0) {
                    spans[it]--;
                    spanPad++;
                } else {
                    const td = rowTDs[rIt][it - spanPad] as HTMLTableCellElement;
                    if (td.rowSpan && td.rowSpan > 1) {
                        spans[it] = td.rowSpan - 1;
                    }
                    const string = td.innerText;
                    if (/^\s*$/.test(string)) {
                        // empty string
                        currentCell = {
                            type: ITableElementType.Number,
                            value: 0,
                        };
                    } else {
                        const numeric = Number(string);
                        if (Number.isNaN(numeric)) {
                            currentCell = {
                                type: ITableElementType.String,
                                value: string,
                            };
                        } else {
                            currentCell = {
                                type: ITableElementType.Number,
                                value: numeric,
                            };
                        }
                    }
                }
                result[it].push(currentCell);
            }
        }
        return result;
    }

    //From: https://stackoverflow.com/a/53660837
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    median(numbers: number[]) {
        const sorted = numbers.slice().sort((a, b) => a - b);
        const middle = Math.floor(sorted.length / 2);

        if (sorted.length % 2 === 0) {
            return (sorted[middle - 1] + sorted[middle]) / 2;
        }

        return sorted[middle];
    }
}

PrintProcessor.addFunction(TableSummary.uid, new TableSummary());

// function testTable() {
//     const s = new TableSummary()
//     const t = `<table>
//     <tr><td>sadasd</td><td>sdadasd</td><td>sdadas</td></tr>
//     <tr><td rowspan="3">1.1</td> <td rowspan="2">1.2</td> <td>1.3</td>               <td>1.3</td>       <td>1.3</td></tr>
//     <tr>                                                  <td>2.3</td>               <td></td>          <td>a</td></tr>
//     <tr>                         <td>3.2</td>             <td rowspan="3">3.3</td>   <td>  </td>        <td>1.3</td></tr>
//     <tr><td>4.1</td>             <td rowspan="3">4.2</td>                            <td>       </td>   <td>1.3</td></tr>
//     <tr><td>5.1</td>                                                                 <td>1</td>         <td>1.3</td></tr>
//     <tr><td>6.1</td>                                      <td>6.3</td>               <td></td>          <td>1.3</td></tr>
//     </table>`;
//     const normalised = s.normalizeTable(t)
//     console.log(`col1 has ${normalised[0].length} items and ${normalised[0].filter(row => row.type !== ITableElementType.Span).length} without spans`)
//     console.log(`col2 has ${normalised[1].length} items and ${normalised[1].filter(row => row.type !== ITableElementType.Span).length} without spans`)
//     console.log(`col3 has ${normalised[2].length} items and ${normalised[2].filter(row => row.type !== ITableElementType.Span).length} without spans`)
//     console.log(`col4 has ${normalised[3].length} items and ${normalised[3].filter(row => row.type !== ITableElementType.Span).length} without spans`)
//
//     console.log(`Count col0 ${s.summarize(normalised[0], ITableSummaryType.Count, () => {})}`)
//     console.log(`Sum col0 ${s.summarize(normalised[0], ITableSummaryType.Sum, () => {})}`)
//     console.log(`Avg col0 ${s.summarize(normalised[0], ITableSummaryType.Average, () => {})}`)
//     console.log(`Med col0 ${s.summarize(normalised[0], ITableSummaryType.Median, () => {})}`)
//     console.log(`Avg col3 ${s.summarize(normalised[3], ITableSummaryType.Average, () => {})}`)
//     console.log(`Avg col4 ${s.summarize(normalised[4], ITableSummaryType.Average, () => {})}`)
//     return normalised;
// }
