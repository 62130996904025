import { ml } from "../../../../core/common/matrixlib";
import { IPrintFunction, IGlobalPrintFunctionParams } from "../../../../core/printinterface/PrintFunction";
import { IPrintFieldInfo } from "../../../../core/printinterface/PrintIterators";
import { IPrintGlobals } from "../../../../core/printinterface/PrintProcessorInterfaces";
import { FieldIterator } from "../../iterators/FieldIterator";
import { PrintProcessor } from "../../PrintProcessor";

export type { IFieldContentParams };
export { FieldContent };

interface IFieldContentParams {
    fieldName?: string; // default:"". name of the field to shown
    raw?: boolean; // default:false. if set to true the xml representation of the field is shown
    fieldInfo?: IPrintFieldInfo; // if field comes from iterator use this
    function?: string; // name of function to render... if not defined it uses the field type
    functionPrefix?: string; // to prefix the field type (if prefix type does not exists it uses the field type)
}

class FieldContent implements IPrintFunction {
    static uid = "fieldContent";

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getGroup() {
        return "Fields";
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp(hideDetails?: boolean, showFieldName?: boolean) {
        let help = `<h1>Renders field of an item or a folder</h1>
<p>Each field uses it's own default or a custom renderer. Some options are shared between the fields.</p>
<p>Common Options</p>
<pre>
    ${showFieldName ? "fieldName:string // name of the field to be shown" : ""}
    function:string // default:"". name of function to render... if not defined it uses the field type
    functionPrefix:string // default:"". used as prefix of field type to find render function
    class:"" // additional class for outmost container, besides field type
    raw?:boolean // default:false. if set to true the xml representation of the field is shown (useful to determine path a specific attribute)
</pre>`;
        if (!hideDetails) {
            help += "<p>Options depending on field type:</p>";
            $.each(PrintProcessor.getFunctions(PrintProcessor.FIELD_FUNCTION_TYPE), function (uid, fct) {
                help += fct.getHelp();
            });
        }
        return help;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Render contents of fields";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsCaller: IFieldContentParams,
        itemOrFolderRef: string,
        item: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        if (!paramsCaller.fieldInfo && !paramsCaller.fieldName) {
            onError("FieldContent needs information about field to render (a field name or a field from an iterator)");
            return "";
        }

        const paramsDefaults: IFieldContentParams = {};

        const params = <IFieldContentParams>ml.JSON.clone({
            ...paramsDefaults,
            ...overwrites.customer[FieldContent.uid],
            ...paramsCaller,
            ...overwrites.project[FieldContent.uid],
            ...overwrites.section[FieldContent.uid],
        });

        if (params.fieldName) {
            let fields: IPrintFieldInfo[] | null = null;
            if (!params.function && !params.functionPrefix && !params.raw && !params.fieldInfo) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                let type = itemOrFolderRef.indexOf("F-") == 0 ? "FOLDER" : itemOrFolderRef.split("-")[0];
                let fieldId = globals.fieldIdByLabel[type + "-" + params.fieldName.toLowerCase()];

                let fieldInfo: IPrintFieldInfo;
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (fieldId && globals.lastItem == itemOrFolderRef) {
                    // get it from cache (if it was cached before)
                    fieldInfo = globals.lastFields["f" + fieldId];
                    if (fieldInfo) {
                        fields = [fieldInfo];
                    }
                }
            }

            if (!fields) {
                let iterator = PrintProcessor.getFieldIterator(FieldIterator.uid);
                fields = iterator
                    ? await iterator.iterate(
                          overwrites,
                          { showNames: [params.fieldName] },
                          itemOrFolderRef,
                          mf,
                          globals,
                          possibleTargets,
                          onError,
                      )
                    : null;
            }
            if (fields && fields.length) {
                params.fieldInfo = fields[0];
            } else {
                // nothing to render -> field does not exist
                return "";
            }
        }

        // get render function
        if (params.raw && params.fieldInfo) {
            let xml = $(
                `<pre style="background-color: #f3f3f3;border: 1px solid #dddddd;border-radius: 6px;padding: 6px;">`,
            );
            let field_xml = $("field_xml", params.fieldInfo.field);
            if (field_xml.length) {
                xml.text(this.formatXml($(field_xml[0].outerHTML.replace(/\n/g, ""))[0], 0).outerHTML);
            } else {
                field_xml = $("test_case", params.fieldInfo.field);
                if (field_xml.length) {
                    xml.text(this.formatXml($(field_xml[0].outerHTML.replace(/\n/g, ""))[0], 0).outerHTML);
                } else {
                    field_xml = $("xml_data", params.fieldInfo.field);
                    if (field_xml.length) {
                        xml.text(this.formatXml($(field_xml[0].outerHTML.replace(/\n/g, ""))[0], 0).outerHTML);
                    } else {
                        // no xml data
                    }
                }
            }
            return xml[0].outerHTML;
        }
        let fieldProcessor: IPrintFunction | null = null;
        if (params.function) {
            fieldProcessor = PrintProcessor.getFunction(params.function);
            if (!fieldProcessor) {
                onError(`Function to print field "${params.function}" does not exist`);
                return "";
            }
        } else if (params.functionPrefix && params.fieldInfo) {
            fieldProcessor = PrintProcessor.getFunction(params.functionPrefix + params.fieldInfo.type);
        }

        if (!fieldProcessor && params.fieldInfo) {
            // use the default (name of field type)
            fieldProcessor = PrintProcessor.getFieldFunction(params.fieldInfo.type);
        }

        if (!fieldProcessor) {
            // TODO: Move this check into a central place? This is also done in FieldIterator
            // don't know how to render a field that can happen
            if (params.fieldInfo && params.fieldInfo.type) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (["uplinkinfo", "links"].indexOf(params.fieldInfo.type) == -1) {
                    // console.log("Links are rendered through TRACES: " +  params.fieldInfo?.type );
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                } else if (["labels"].indexOf(params.fieldInfo.type) == -1) {
                    // console.log("Labels are rendered through labels: " +  params.fieldInfo?.type );
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                } else if (["section"].indexOf(params.fieldInfo.type) == -1) {
                    // console.log("Field does not support printing:  " +  params.fieldInfo?.type );
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                } else if (["colorPicker", "syncStatus", "syncSourceInfo"].indexOf(params.fieldInfo.type) == -1) {
                    console.log("Field does not support printing: " + params.fieldInfo?.type);
                } else {
                    console.log("Don't know how to render: " + params.fieldInfo?.type);
                }
            }
            return "";
        }

        // render

        return fieldProcessor.renderAsync(
            overwrites,
            ml.JSON.clone({ ...paramsCaller, ...{ fieldInfo: params.fieldInfo } }),
            itemOrFolderRef,
            item,
            mf,
            globals,
            possibleTargets,
            onError,
        );
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    protected formatXml(node: Element, level: number) {
        let indentBefore = new Array(level++ + 1).join("  ");
        let indentAfter = new Array(level - 1).join("  ");
        let textNode: Text;

        for (let idx = 0; idx < node.children.length; idx++) {
            textNode = document.createTextNode("\n" + indentBefore);
            node.insertBefore(textNode, node.children[idx]);

            this.formatXml(node.children[idx], level);

            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (node.lastElementChild == node.children[idx]) {
                textNode = document.createTextNode("\n" + indentAfter);
                node.appendChild(textNode);
            }
        }

        return node;
    }
}

class FieldItemContent extends FieldContent {
    static uid = "fieldItemContent";
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getGroup() {
        return "Item";
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Contents of a field";
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp(hideDetails?: boolean) {
        return super.getHelp(hideDetails, true);
    }
}

PrintProcessor.addFunction(FieldContent.uid, new FieldContent());
PrintProcessor.addFunction(FieldItemContent.uid, new FieldItemContent());
