import { PostProcessor } from "../PrintProcessorRegistry";
import { IPrintFunctionParams } from "../../../core/printinterface/PrintFunction";

interface IMergeColumnsParams extends IPrintFunctionParams {
    source?: number[];
    target?: number;
}

// example -> merge several columns into one
export const mergeColumns: PostProcessor = {
    run: (tableHtml: string, params: IMergeColumnsParams) => {
        let table = $(tableHtml);

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (typeof params["source"] == "undefined") {
            return "mergeColumns - source columns missing";
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (typeof params["target"] == "undefined") {
            return "mergeColumns - target column missing";
        }

        // find the first row to do the stuff
        // iterate over all rows and do the thing
        let rows = table.children().children("tr");
        for (let row = 0; row < rows.length; row++) {
            const tds = $(rows[row]).children("td,th");
            let html = "";

            for (let col of params["source"]) {
                let colNumber = col;

                if (colNumber < 0) {
                    colNumber = tds.length + colNumber;
                }

                html += tds[colNumber].innerHTML;
            }

            let targetNumber = params["target"];

            if (targetNumber < 0) {
                targetNumber = tds.length + targetNumber;
            }

            tds[targetNumber].innerHTML = html;
        }

        return table[0].outerHTML;
    },
};
