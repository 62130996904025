import { ml } from "../../../../core/common/matrixlib";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../PrintProcessor";
import { TitlePrimitive } from "./TitlePrimitive";

export type { IIndentParams };
export { IndentPrimitive };

interface IIndentParams {
    recursionDepth: number; // supplied by caller (depth of iteration)
    offset: number; //  default 0
    width: number; // default 16 (indent 16 pixels)
}

class IndentPrimitive implements IPrintFunction {
    static uid = "indent";

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getGroup() {
        return "Depth";
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp() {
        return `<h1>Indent depending on recursion depth</h1>
<p>Options</p>
<pre>
    offset: number //  default 0: just use depth * multiplicator pixels
    width:number // default 16 (indent 16 pixels)
</pre>`;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Indent depending on recursion depth";
    }

    private defaults: IIndentParams = {
        recursionDepth: 0, // supplied by caller (depth of iteration)
        offset: 0, //  default 0
        width: 16, // default 16 (indent 16 pixels)
    };
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IIndentParams>paramsIn;
        const params = ml.JSON.clone({
            ...this.defaults,
            ...overwrites.customer[TitlePrimitive.uid],
            ...paramsCaller,
            ...overwrites.project[TitlePrimitive.uid],
            ...overwrites.section[TitlePrimitive.uid],
        });

        return `<span style='display: inline-block; width:${
            (params.offset + params.recursionDepth - 1) * params.width
        }px'></span>`;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    editParams(params: IIndentParams, onUpdate: (newParams: IIndentParams) => void) {
        let ui = $("<div>");

        let org = <IIndentParams>ml.JSON.clone({ ...this.defaults, ...params });

        ml.UI.addDropdownNumber(ui, "Offset to actual depth", org, "offset", -10, 10, () => {
            onUpdate(org);
        });
        ml.UI.addDropdownNumber(ui, "Width in pixel", org, "width", 0, 100, () => {
            onUpdate(org);
        });

        return ui;
    }
}

PrintProcessor.addFunction(IndentPrimitive.uid, new IndentPrimitive());
