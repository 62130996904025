import { ml } from "../../../core/common/matrixlib";
import { IPrintGlobals } from "../../../core/printinterface/PrintProcessorInterfaces";
import { IPrintSorter } from "../../../core/printinterface/PrintSorter";
import { PrintProcessor } from "../PrintProcessor";

export { PrintSortByItemId };

class PrintSortByItemId implements IPrintSorter {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp() {
        return `<h1>Sort by item id</h1>
    <p>First folders, than items. Than by category alphabetic, than by id.</p>`;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Sort by item id";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    sort(
        ia: string,
        ib: string,
        inverse: boolean,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        params: any,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        let a = ml.Item.parseRef(ia);
        let b = ml.Item.parseRef(ib);
        if (!a || !b) {
            return 0;
        }

        if (a.isFolder && !b.isFolder) {
            return inverse ? 1 : -1;
        } // leave
        if (b.isFolder && !a.isFolder) {
            return inverse ? -1 : 1;
        } // swap

        if (a.type < b.type) {
            return inverse ? 1 : -1;
        } // leave
        if (b.type < a.type) {
            return inverse ? -1 : 1;
        } // swap

        if (a.number < b.number) {
            return inverse ? 1 : -1;
        } // leave
        if (b.number < a.number) {
            return inverse ? -1 : 1;
        } // swap
        // leave
        return 0;
    }
}

PrintProcessor.addItemSorter("itemid", new PrintSortByItemId());
