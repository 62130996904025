import { ml } from "../../../core/common/matrixlib";
import { IStringStringArrayMap } from "../../../core/globals";
import { IGlobalPrintFunctionParams } from "../../../core/printinterface/PrintFunction";
import { IPrintItemIteratorParams, IPrintItemIterator } from "../../../core/printinterface/PrintIterators";
import { IPrintGlobals } from "../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../PrintProcessor";

export type { ILinksIteratorParams };
export { LinksIterator };

interface ILinksIteratorParams extends IPrintItemIteratorParams {
    direction: "up" | "down"; // default "down". whether to show up or down links
    leafs?: string[]; // default:unset. if leafs is set to a list of categories, e.g["SPEC","XTC"] it goes through all references recursively until it finds items of the specified categories
    maxDepth?: number; // default:5. max depth when going recursively
    xtc?: "highest" | "changed" | ""; // default:"". if set not all XTCs of an item but only the one with the highest id or the last modified will be returned
    excludeRiskControlDown: boolean; // default:true. if set does not render risk controls as downlinks
    onlyRiskControlDown: boolean; // default:false. only shows downlinks which are risk controls
    categories: null | []; // default:unset. if set only includes items of these categories
    // these can be simple categories or complete comma separated paths, e.g.
    // categories:["VAR"] returns all downlinks of an item which are of type VAR
    // categories:["VAR,XTC"] returns all XTC of the VARs of the item (but if the item has links to TC which also have XTC these are not included))
    // categories:["VAR", "VAR,XTC"] returns the union of the both above
    // categories:["VAR", "VAR,XTC", "SREQ,SW"] returns the above + all SW items linked through an SREQ
}

class LinksIterator implements IPrintItemIterator {
    worksOnItem = true;
    worksOnFolder = false;
    folderIterator = false;
    traceIterator = true;
    tableRowIterator = false;

    static uid = "links";

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp() {
        return `<h1>Iterator over up or down links of an item</h1>
<p>Options</p>
<pre>
    direction:"up"|"down", // default "down". whether to show up or down links
    leafs?:string[] // default:unset. if leafs is set to a list of categories, e.g["SPEC","XTC"] it goes through all references recursively until it finds items of the specified categories
    maxDepth?:number // default:5. max depth when going recursively
    xtc?:"highest"|"changed"|"" // default:"". if set not all XTCs of an item but only the one with the highest id or the last modified will be returned
    excludeRiskControlDown:boolean // default:true. if set does not render risk controls as downlinks
    onlyRiskControlDown:boolean // default:false. only shows downlinks which are risk controls
    categories:string[] // default:unset. if set only includes items of these categories
    // these can be simple categories or complete comma separated paths, e.g.
    // categories:["VAR"] returns all downlinks of an item which are of type VAR
    // categories:["VAR,XTC"] returns all XTC of the VARs of the item (but if the item has links to TC which also have XTC these are not included))
    // categories:["VAR", "VAR,XTC"] returns the union of the both above
    // categories:["VAR", "VAR,XTC", "SREQ,SW"] returns the above + all SW items linked through an SREQ
</pre>`;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Links of item";
    }

    getValidation(): null {
        return null;
    }

    private paramsDefaults: ILinksIteratorParams = {
        direction: "down", // default down
        maxDepth: 5,
        excludeRiskControlDown: false,
        onlyRiskControlDown: false,
        categories: null,
    };

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async iterate(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintItemIteratorParams,
        itemOrFolder: string,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <ILinksIteratorParams>paramsIn;
        let that = this;

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (itemOrFolder.indexOf("F-") == 0) {
            onError(`this needs to be an item id: "${itemOrFolder}"`);
            return [];
        }
        const item = globals.itemMap[itemOrFolder];
        if (!item) {
            onError(`cannot iterate over links of unknown item "${itemOrFolder}"`);
            return [];
        }

        const params = ml.JSON.clone({
            ...this.paramsDefaults,
            ...overwrites.customer[LinksIterator.uid],
            ...paramsCaller,
            ...overwrites.project[LinksIterator.uid],
            ...overwrites.section[LinksIterator.uid],
        });

        let riskControlCategories = globals.riskControlCategories[ml.Item.parseRef(itemOrFolder).type];

        let excludedCategories = params.excludeRiskControlDown ? riskControlCategories : [];
        let requiredCategories = params.onlyRiskControlDown ? riskControlCategories : null;
        if (params.categories) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (requiredCategories == null) {
                requiredCategories = [];
            }
            requiredCategories = requiredCategories.concat(params.categories);
        }

        let all = this.getNextLinks(
            params,
            itemOrFolder,
            item,
            mf,
            globals,
            possibleTargets,
            0,
            excludedCategories,
            requiredCategories,
            "",
        );
        if (!params.xtc) {
            return LinksIterator.deduplicate(all);
        }
        // group all the XTC's by their parent
        let grouped: IStringStringArrayMap = {};
        for (const itemId of all) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (itemId.indexOf("XTC-") == 0) {
                let parent = this.getParentTC(paramsCaller, itemId, mf, globals, onError);
                if (grouped[parent]) {
                    // another XTC for a TC
                    grouped[parent].push(itemId);
                } else {
                    // first XTC for a TC
                    grouped[parent] = [itemId];
                }
            }
        }
        // now filter the returned XTCs
        let filtered = all.filter(function (itemId) {
            let parent = that.getParentTC(paramsCaller, itemId, mf, globals, onError);
            let siblings = grouped[parent];
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (itemId.indexOf("XTC-") == 0 && params.xtc) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (params.xtc.toLowerCase() == "changed") {
                    // return only last changed
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    return itemId == that.getLastChangedId(siblings, globals);
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                } else if (params.xtc.toLowerCase() == "highest") {
                    // return only the highest id
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    return itemId == that.getHighestNumberedId(siblings);
                } else {
                    return true;
                }
            } else {
                return true;
            }
        });
        return filtered;
    }

    public static deduplicate(ids: string[]): string[] {
        const returnSet: { [id: string]: null } = ids.reduce((accum: Record<string, null>, item) => {
            accum[item] = null;
            return accum;
        }, {});
        return Object.keys(returnSet);
    }

    // get hightest running id (numerical)
    private getHighestNumberedId(ids: string[]): string {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (ids.length == 1) {
            return ids[0];
        }
        let sorted = ids.sort((a, b) => {
            return Number(a.split("-")[1]) - Number(b.split("-")[1]);
        });
        return sorted[sorted.length - 1];
    }

    // get last changed
    private getLastChangedId(ids: string[], globals: IPrintGlobals): string {
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (ids.length == 1) {
            return ids[0];
        }
        let sorted = ids.sort((a, b) => {
            return (
                new Date(globals.itemMap[a].attr("birth")).getTime() -
                new Date(globals.itemMap[b].attr("birth")).getTime()
            );
        });
        return sorted[sorted.length - 1];
    }

    // get most likely parent (ignore possible items) -> there should ex
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private getParentTC(
        params: ILinksIteratorParams,
        itemId: string,
        mf: JQuery,
        globals: IPrintGlobals,
        onError: (message: string) => void,
    ) {
        let item = globals.itemMap[itemId];
        let sources = item.children("UPLINK");

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (sources.length == 1) {
            // normal case: one XTC has one TC
            return sources[0].getAttribute("ref") || "";
        }
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (sources.length == 0) {
            // TC was deleted - should never happen really
            return itemId;
        }
        // there's more than one parent -> should not happen in post 2.1 projects
        let xtcTitle = item[0].getAttribute("title") || "";
        for (let source of sources.toArray()) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (xtcTitle.indexOf(source.getAttribute("title") + " (") == 0) {
                return source.getAttribute("ref") || "";
            }
        }

        return itemId;
    }

    // next next links, one or more levels down
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private getNextLinks(
        params: ILinksIteratorParams,
        itemOrFolder: string,
        item: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        depth: number,
        excludeCats: string[],
        requireCats: null | string[],
        catPath: string,
    ) {
        let that = this;

        let next: string[] = [];

        depth++;
        // see if they are cached
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let traces = params.direction == "down" ? globals.down[itemOrFolder] : globals.up[itemOrFolder];
        if (!traces) {
            // if not get them from tree
            globals.count++;
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            let children = item.children(params.direction == "down" ? "DOWNLINK" : "UPLINK").toArray();

            traces = children ? children.map((trace) => trace.getAttribute("ref") || "") : [];
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            params.direction == "down" ? (globals.down[itemOrFolder] = traces) : (globals.up[itemOrFolder] = traces);
        }

        for (let childId of traces) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (possibleTargets.indexOf(childId) != -1) {
                let category = childId.split("-")[0];
                let nextPath = catPath + (catPath ? "," : "") + category;
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (excludeCats.length && excludeCats.indexOf(category) != -1) {
                    // this is an excluded category
                } else if (requireCats) {
                    // we need to obey the required cat paths

                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (requireCats.indexOf(nextPath) != -1) {
                        // this needs to be included
                        next.push(childId);
                    }
                    // check if it's worthwhile to continue
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    let possiblePaths = requireCats.filter((path) => path.indexOf(nextPath + ",") != -1);
                    if (possiblePaths.length) {
                        // still something to follow
                        next = next.concat(
                            that.getNextLinks(
                                params,
                                childId,
                                globals.itemMap[childId],
                                mf,
                                globals,
                                possibleTargets,
                                depth,
                                excludeCats,
                                possiblePaths,
                                nextPath,
                            ),
                        );
                    }
                } else if (params.leafs) {
                    // TODO: MATRIX-7555: lint errors should be fixed for next line
                    // eslint-disable-next-line
                    if (params.leafs.indexOf(category) != -1) {
                        // this is a wanted leaf.. stop here
                        next.push(childId);
                    } else {
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        if (params.maxDepth != null && depth < params.maxDepth) {
                            next = next.concat(
                                that.getNextLinks(
                                    params,
                                    childId,
                                    globals.itemMap[childId],
                                    mf,
                                    globals,
                                    possibleTargets,
                                    depth,
                                    excludeCats,
                                    requireCats,
                                    nextPath,
                                ),
                            );
                        }
                    }
                } else {
                    next.push(childId);
                }
            }
        }

        return next;
    }

    // get next categories which are leaves, e.g. ["A","B,C","D","A"] will return A,D,A (as B is not a leaf)
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private getNextIncludedPaths(requireCats: string[]) {
        let next: string[] = [];
        for (let catPath of requireCats) {
            let parts = catPath.split(",");
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (parts.length == 1) {
                next.push(parts[0]);
            }
        }
        return next;
    }

    // follow the path (category), e.g. (["A","B,C","D","A"],A) => [], (["A","B,C","D","A"],B) => [C]
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private getNextPaths(requireCats: string[], category: string) {
        let next: string[] = [];

        for (let catPath of requireCats) {
            let parts = catPath.split(",");
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (parts.length > 1 && parts[0] == category) {
                // this needs to be included (e.g. the "B,C" tested against "B")
                parts.splice(0, 1); // remove the category
                next.push(parts.join(","));
            }
        }

        return next;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    editParams(params: ILinksIteratorParams, onUpdate: (newParams: ILinksIteratorParams) => void) {
        let ui = $("<div>");

        let org = <ILinksIteratorParams>ml.JSON.clone({ ...this.paramsDefaults, ...params });

        ml.UI.addDropdownToValue(
            ui,
            "direction to follow (up or down traces)",
            org,
            "direction",
            [
                { id: "up", label: "up traces" },
                { id: "down", label: "down traces" },
            ],
            false,
            false,
            () => {
                onUpdate(org);
            },
            "down traces",
        );
        ml.UI.addDropdownToArray(
            ui,
            "specify a list of leaf categories to be included",
            org,
            "leafs",
            [],
            [],
            100,
            true,
            false,
            () => {
                onUpdate(org);
            },
            "all categories",
        );

        ml.UI.addDropdownNumber(ui, "maximum depth of children", org, "maxDepth", 0, 20, () => {
            onUpdate(org);
        });

        const xtcOpt = [
            { id: "", label: "enumerate all XTCs" },
            { id: "highest", label: "per test return XTC with highest item id" },
            { id: "changed", label: "per test return XTC which was last changed" },
        ];
        ml.UI.addDropdownToValue(ui, "handling of XTCS", org, "xtc", xtcOpt, false, false, () => {
            onUpdate(org);
        });

        ml.UI.addCheckbox(ui, "Do not show risk controls as downlinks", org, "excludeRiskControlDown", () => {
            onUpdate(org);
        });
        ml.UI.addCheckbox(ui, "Only include downlinks which are risk controls", org, "onlyRiskControlDown", () => {
            onUpdate(org);
        });

        const x = { str: org.categories ? JSON.stringify(org.categories) : "no limitations" };
        ml.UI.addTextInput(ui, "Iteration paths (see advanced)", x, "str", () => {}, undefined, false, undefined, true);

        return ui;
    }
}

PrintProcessor.addItemIterator(LinksIterator.uid, new LinksIterator());
