import { IPrintGlobals } from "../../../core/printinterface/PrintProcessorInterfaces";
import { IPrintSorter } from "../../../core/printinterface/PrintSorter";
import { PrintProcessor } from "../PrintProcessor";

export { PrintSortByRevisionDate };

class PrintSortByRevisionDate implements IPrintSorter {
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp() {
        return `<h1>Sort by last revision date</h1>`;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Sort by revision date";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    sort(
        a: string,
        b: string,
        inverse: boolean,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        params: any,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        let birthA = new Date(globals.itemMap[a][0].getAttribute("birth") || "");
        let birthB = new Date(globals.itemMap[b][0].getAttribute("birth") || "");
        return inverse ? birthB.getTime() - birthA.getTime() : birthA.getTime() - birthB.getTime();
    }
}

PrintProcessor.addItemSorter("date", new PrintSortByRevisionDate());
