import { ml } from "../../../../../core/common/matrixlib";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../../core/printinterface/PrintProcessorInterfaces";
import { IDropDownConfig } from "../../../../../core/ProjectSettings";
import { ITableReviewData, ReviewControlColumns } from "../../../../../core/client/plugins/ScheduleReviewDefines";
import { FieldHelper } from "../FieldHelper";
import { PrintProcessor } from "../../../PrintProcessor";
import { IPrintFieldParams } from "../../../PrintValueOf";
import { LinkPrimitive } from "../../items/LinkPrimitive";

export type { IFieldReviewControlParams };
export { FieldReviewControl };

interface IFieldReviewControlParams extends IPrintFieldParams {
    headerItem: string; // default:"Item". heading of column for item ids. if set to "" the column will not be shown
    headerRevision: string; // default:"Revision". heading of column for item revision numbers. if set to "" the column will not be shown
    headerComments: string; // default:"Comments". heading of column for comments. if set to "" the column will not be shown
    showUsers: boolean; // default:true. set to false to not show user columns

    // user details - these are shared with other fields
    login: boolean; // default:false. if set to true show login (user id)
    first: boolean; // default:true. if set to true show first name
    last: boolean; // default:true. if set to true show last name
    email: boolean; // default:false. if set to true show email

    classReviewTable: string; // default:"table table-bordered". additional class for outermost container

    class: string; // default:"". additional class for outermost container
}

class FieldReviewControl implements IPrintFunction {
    static uid = PrintProcessor.getFieldFunctionId("reviewcontrol");

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getGroup() {
        return PrintProcessor.FIELD_FUNCTION_TYPE;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp() {
        return `<h2>review</h2>
<pre>
    headerItem:string // default:"Item". heading of column for item ids. if set to "" the column will not be shown
    headerRevision:string // default:"Revision". heading of column for item revision numbers. if set to "" the column will not be shown
    headerComments:string // default:"Comments". heading of column for comments. if set to "" the column will not be shown
    showUsers:boolean // default:true. set to false to not show user columns

    // user details - these are shared with other fields
    login:boolean // default:false. if set to true show login (user id)
    first:boolean // default:true. if set to true show first name
    last:boolean // default:true. if set to true show last name
    email:boolean // default:false. if set to true show email

    classReviewTable:string // default:"table table-bordered". additional class for outermost container
</pre>`;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Review field renderer (default implementation)";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IFieldReviewControlParams>paramsIn;
        const defaults: IFieldReviewControlParams = {
            headerItem: "Item",
            headerRevision: "Revision",
            headerComments: "Comments",
            showUsers: true,

            login: false,
            first: true,
            last: true,
            email: false,

            classReviewTable: `table table-bordered`,

            class: "",
        };

        const params = ml.JSON.clone({
            ...defaults,
            ...overwrites.customer[FieldReviewControl.uid],
            ...paramsCaller,
            ...overwrites.project[FieldReviewControl.uid],
            ...overwrites.section[FieldReviewControl.uid],
        });

        if (!paramsCaller.fieldInfo || !paramsCaller.fieldInfo.field) {
            onError("called a field rendering function without passing a field");
            return "";
        }

        let data = <ITableReviewData>paramsCaller.fieldInfo.jsonValue;
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (!data || !data.reviewtable || data.reviewtable.length == 0) {
            return ``;
        }
        let reviewTable = data.reviewtable;

        // get users
        let users: string[] = [];
        for (let col in reviewTable[0]) {
            if (
                col !== ReviewControlColumns.COL_VERSION && // will not happen as these are in some weird comment struct
                col !== ReviewControlColumns.COL_ANNOTATIONS && // will not happen as these are in some weird comment struct
                col !== ReviewControlColumns.COL_ITEM && // not a user but item id
                col !== ReviewControlColumns.COL_COMMENT_LOG && // not a user but comments
                !col.startsWith("_g_") // skip the users who update the field on behalf of the group
            ) {
                users.push(col);
            }
        }

        let rendered = `<table class='${params.class} ${params.classReviewTable} ${FieldReviewControl.uid}'>`;

        // render table header
        rendered += `<thead>`;
        if (params.headerItem) {
            rendered += `<th>${params.headerItem}</th>`;
        }
        if (params.headerRevision) {
            rendered += `<th>${params.headerRevision}</th>`;
        }
        if (params.showUsers) {
            for (let user of users) {
                rendered += `<th>${PrintProcessor.getUserName(
                    user,
                    mf,
                    params.first,
                    params.last,
                    params.login,
                    params.email,
                )}</th>`;
            }
        }
        if (params.headerComments) {
            rendered += `<th>${params.headerComments}</th>`;
        }
        rendered += `</thead>`;

        // get dropdown of pass fail info
        let ddSetting =
            params.fieldInfo && params.fieldInfo.jsonConfig
                ? params.fieldInfo.jsonConfig.statusDropdown
                : "dd_reviewOptions";
        let dropdown: IDropDownConfig = <IDropDownConfig>PrintProcessor.getJsonConfig(ddSetting, mf);
        if (!dropdown || !dropdown.options) {
            dropdown = { options: [] };
        }

        rendered += `<tbody>`;
        for (let reviewLine of reviewTable) {
            let itemId = reviewLine[ReviewControlColumns.COL_ITEM].split("!")[0];
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (possibleTargets.indexOf(itemId) != -1) {
                rendered += `<tr>`;

                if (params.headerItem) {
                    rendered += `<td>${await PrintProcessor.getFunction(LinkPrimitive.uid)?.renderAsync(
                        overwrites,
                        { ...{ titleAfterLink: true }, ...params },
                        itemId,
                        globals.itemMap[itemId],
                        mf,
                        globals,
                        possibleTargets,
                        onError,
                    )}</td>`;
                }
                if (params.headerRevision) {
                    rendered += `<td>${reviewLine[ReviewControlColumns.COL_VERSION]}</td>`;
                }
                if (params.showUsers) {
                    for (let user of users) {
                        let status = reviewLine[user];
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        let option = dropdown.options.filter((ddo) => ddo.id == status);
                        let optionName = option.length ? option[0].label : "";
                        rendered += `<td>${optionName}</td>`;
                    }
                }
                if (params.headerComments) {
                    rendered += `<td>${reviewLine[ReviewControlColumns.COL_COMMENT_LOG]}</td>`;
                }

                rendered += `</tr>`;
            }
        }
        rendered += `</tbody>`;

        rendered += "</table>";
        return FieldHelper.fixTermsAndAbbreviation(rendered, mf);
    }
}

PrintProcessor.addFunction(FieldReviewControl.uid, new FieldReviewControl());
