import { ml } from "../../../../../core/common/matrixlib";
import { globalMatrix } from "../../../../../core/globals";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../../PrintProcessor";
import { IPrintFieldParams } from "../../../PrintValueOf";

export type { IFieldCrossLinksParams };
export { FieldCrossLinks };

interface IFieldCrossLinksParams extends IPrintFieldParams {
    formatCross: "list" | "comma"; //  default:"comma". list or comma separated, if set to something other than list or comma it is used as a separator string
    asLinkCross: boolean; // default:true. if set show links as hyperlinks
    showTitle: boolean; // default:true. shows title of referenced items
    class: string; // default:"". additional class for outermost container
}

class FieldCrossLinks implements IPrintFunction {
    static uid = PrintProcessor.getFieldFunctionId("crosslinks");

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getGroup() {
        return PrintProcessor.FIELD_FUNCTION_TYPE;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp() {
        return `<h2>cross link</h2>
<p>Options</p>
<pre>
    formatCross:"list"|"comma" //  default:"comma". list or comma separated, if set to something other than list or comma it is used as a separator string
    asLinkCross:boolean // default:true. if set show links as hyperlinks
    showTitle:boolean // default:true. shows title of referenced items
</pre>`;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Cross project links field renderer (default implementation)";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IFieldCrossLinksParams>paramsIn;
        const defaults: IFieldCrossLinksParams = {
            formatCross: "list", // list or comma separated, default list
            asLinkCross: true,
            showTitle: true,
            class: "",
        };

        const params = ml.JSON.clone({
            ...defaults,
            ...overwrites.customer[FieldCrossLinks.uid],
            ...paramsCaller,
            ...overwrites.project[FieldCrossLinks.uid],
            ...overwrites.section[FieldCrossLinks.uid],
        });

        if (!paramsCaller.fieldInfo || !paramsCaller.fieldInfo.field) {
            onError("called a field rendering function without passing a field");
            return "";
        }

        let rendered = `<div class='${params.class} ${FieldCrossLinks.uid}'>`;

        if (paramsCaller.fieldInfo.jsonValue) {
            let refs: string[] = [];
            $.each(paramsCaller.fieldInfo.jsonValue, function (idx, fileRef) {
                let project = fileRef.projectShortLabel;
                let item = fileRef.to;
                let title = " <span>" + fileRef.title + "</span>";

                if (params.asLinkCross) {
                    refs.push(
                        `<span class="crossLink"><a href="${
                            globalMatrix.matrixBaseUrl
                        }/${project}/${item}">${project}/${item}</a>${params.showTitle ? title : ""}</span>`,
                    );
                } else {
                    refs.push(`<span class="crossLink">${project}/${item} ${params.showTitle ? title : ""}</span>`);
                }
            });
            rendered +=
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                params.formatCross == "list"
                    ? "<ul>" + refs.map((u) => `<li>${u}</li>`).join("") + "</ul>"
                    : // TODO: MATRIX-7555: lint errors should be fixed for next line
                      // eslint-disable-next-line
                      refs.join(params.formatCross == "comma" ? ", " : params.formatCross);
        }

        rendered += "</div>";
        return rendered;
    }
}

PrintProcessor.addFunction(FieldCrossLinks.uid, new FieldCrossLinks());
