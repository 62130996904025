import { ml } from "../../../../core/common/matrixlib";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../core/printinterface/PrintProcessorInterfaces";
import { PrintProcessor } from "../../PrintProcessor";
import { IPrintFieldParams } from "../../PrintValueOf";

export type { IFieldLabelParams };
export { FieldLabel };

interface IFieldLabelParams extends IPrintFieldParams {
    // handling of names of checkbox fields.
    // 0 do not show the name (as normally they are rendered behind the checkbox)
    // 1 print the label,
    // 2 print the help text as name if there's any
    checkboxLabel: number; // default:0.
    class: string; // default:"". additional class for outermost container
}

class FieldLabel implements IPrintFunction {
    static uid = "fieldLabel";

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getGroup() {
        return "Fields";
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp() {
        return `<h1>Renders the name of a field</h1>
<p>Options</p>
<pre>
    // handling of names of checkbox fields.
    // 0 do not show the name (as normally they are rendered behind the checkbox)
    // 1 print the label,
    // 2 print the help text as name if there's any
    checkboxLabel:number // default:0.
    class:string // default:"". additional class for outermost container
</pre>`;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Render name of field";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        item: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IFieldLabelParams>paramsIn;
        const defaults: IFieldLabelParams = {
            checkboxLabel: 0,
            class: "",
        };

        const params = ml.JSON.clone({
            ...defaults,
            ...overwrites.customer[FieldLabel.uid],
            ...paramsCaller,
            ...overwrites.project[FieldLabel.uid],
            ...overwrites.section[FieldLabel.uid],
        });

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (params.fieldInfo == null || params.fieldInfo.name == null) {
            onError("FieldLabel needs information about field to render (a field name or a field from an iterator)");
            return "";
        }
        // special case for checkboxes: then name is rendered behind the checkbox
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (params.fieldInfo.type == "checkbox") {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (params.checkboxLabel == 1) {
                return `<span class='${params.class} ${FieldLabel.uid} checkboxName'>${paramsCaller.fieldInfo?.name}</span>`;
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
            } else if (params.checkboxLabel == 2) {
                // get help text from the config
                return `<span class='${params.class} ${FieldLabel.uid} inlineHelp'>${$(
                    "param_xml inlineHelp",
                    params.fieldInfo.config,
                ).text()}</span>`;
            }

            return "";
        }
        return `<span class='${params.class} ${FieldLabel.uid}'>${paramsCaller.fieldInfo?.name}</span>`;
    }
}

PrintProcessor.addFunction(FieldLabel.uid, new FieldLabel());
