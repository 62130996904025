import { ml } from "../../../../../core/common/matrixlib";
import { IPrintFunction, IGlobalPrintFunctionParams } from "../../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../../core/printinterface/PrintProcessorInterfaces";
import { IDropDownConfig } from "../../../../../core/ProjectSettings";
import { PrintProcessor } from "../../../PrintProcessor";
import { IPrintFieldParams } from "../../../PrintValueOf";

export type { IFieldDropdownParams };
export { FieldDropdown };

interface IFieldDropdownParams extends IPrintFieldParams {
    formatDrop: "list" | "comma"; // default:"comma". list or comma separated, if set to something other than list or comma it is used as a separator string
    class: string; // default:"". additional class for outermost container
}

class FieldDropdown implements IPrintFunction {
    static uid = PrintProcessor.getFieldFunctionId("dropdown");

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getGroup() {
        return PrintProcessor.FIELD_FUNCTION_TYPE;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp() {
        return `<h2>dropdown</h2>
<pre>
    formatDrop:"list"|"comma" // default:"comma". list or comma separated, if set to something other than list or comma it is used as a separator string
</pre>`;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Dropdown field renderer (default implementation)";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFieldParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IFieldDropdownParams>paramsIn;
        const defaults: IFieldDropdownParams = {
            formatDrop: "comma",
            class: "",
        };

        const params = ml.JSON.clone({
            ...defaults,
            ...overwrites.customer[FieldDropdown.uid],
            ...paramsCaller,
            ...overwrites.project[FieldDropdown.uid],
            ...overwrites.section[FieldDropdown.uid],
        });

        if (!paramsCaller.fieldInfo || !paramsCaller.fieldInfo.field) {
            onError("called a field rendering function without passing a field");
            return "";
        }

        let field = paramsCaller.fieldInfo.field;

        //MATRIX-4981 Not escaping because these are IDs and not printed
        let selectedIds = field.length ? PrintProcessor.getCdataAsText(field[0], false) : "";

        let rendered = `<div class='${params.class} ${FieldDropdown.uid}'>`;

        if (selectedIds) {
            let category = field.closest("category");

            let optionSetting = params.fieldInfo.jsonConfig ? params.fieldInfo.jsonConfig.optionSetting : "";
            if (!optionSetting) {
                onError("missing dropdown optionSetting");
                return "";
            }
            let dd = <IDropDownConfig>PrintProcessor.getJsonConfig(optionSetting, mf);
            if (!optionSetting) {
                onError(`missing dropdown setting ${optionSetting}`);
                return "";
            }

            const replaceEntities = function (original: string): string {
                return original.replace(/&lt;/g, "<").replace(/&amp;/g, "&").replace(/&gt;/g, ">");
            };

            const cleanupOptionDisplay = function (original: string): string {
                //MATRIX-4981 Unescape before it gets escaped again to make sure all entities are treated correctly
                let output = replaceEntities(original);
                return $("<div>").text(output).html();
            };

            let selectedText = selectedIds.split(",").map(function (selectedId) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                let options = dd.options.filter((option) => option.id == selectedId);
                if (options.length) {
                    let optionLabel = cleanupOptionDisplay(options[0].label);
                    return `<span class="dd_option dd_${options[0].id}">${optionLabel}</span>`;
                }
                return cleanupOptionDisplay(selectedId);
            });

            rendered +=
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                params.formatDrop == "list"
                    ? "<ul>" + selectedText.map((u) => `<li>${u}</li>`).join("") + "</ul>"
                    : // TODO: MATRIX-7555: lint errors should be fixed for next line
                      // eslint-disable-next-line
                      selectedText.join(params.formatDrop == "comma" ? ", " : params.formatDrop);
        }

        rendered += "</div>";
        return rendered;
    }
}

PrintProcessor.addFunction(FieldDropdown.uid, new FieldDropdown());
