import { ml } from "../../../../core/common/matrixlib";
import {
    IPrintFunction,
    IGlobalPrintFunctionParams,
    IPrintFunctionParams,
} from "../../../../core/printinterface/PrintFunction";
import { IPrintGlobals } from "../../../../core/printinterface/PrintProcessorInterfaces";
import { ITraceConfig } from "../../../../core/ProjectSettings";
import { LinksIterator } from "../../iterators/LinksIterator";
import { PrintProcessor } from "../../PrintProcessor";
import { IRevisionPrimitiveParams } from "./RevisionPrimitive";

export type { ITraceRulesEvaluationParams };
export { TraceRulesEvaluation };

interface ITraceRulesEvaluationParams {
    direction: "up" | "down"; // default:"down". whether to show up or down links
    mustHave: boolean; // default:true. if true show errors about missing required traces
    canHave: boolean; // default:false. if true show errors about not existing optional traces
    before: string; // default:"". prefix of each message
    after: string; // default:"<br/>". postfix of each message
    class: string; // default:"trace_status". class for outermost container
}

class TraceRulesEvaluation implements IPrintFunction {
    static uid = "traceruleCheck";

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getGroup() {
        return "Item";
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp() {
        return `<h1>Renders messages about missing traces</h1>
<p>Options</p>
<pre>
    direction:"up"|"down", // default:"down". whether to show up or down links
    mustHave:boolean // default:true. if true show errors about missing required traces
    canHave:boolean // default:false. if true show errors about not existing optional traces
    before:string // default:"". prefix of each message
    after:string // default:"<br/>". postfix of each message
    class:string // default:"trace_status". class for outermost container
</pre>`;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Missing traces info";
    }

    private paramsDefaults: ITraceRulesEvaluationParams = {
        direction: "down", // default down
        mustHave: true,
        canHave: false,
        before: "",
        after: "</br>",
        class: "trace_status",
    };
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        paramsIn: IPrintFunctionParams,
        itemOrFolderRef: string,
        itemOrFolder: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
    ) {
        const paramsCaller = <IRevisionPrimitiveParams>paramsIn;
        const params = ml.JSON.clone({
            ...this.paramsDefaults,
            ...overwrites.customer[TraceRulesEvaluation.uid],
            ...paramsCaller,
            ...overwrites.project[TraceRulesEvaluation.uid],
            ...overwrites.section[TraceRulesEvaluation.uid],
        });

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (itemOrFolderRef.indexOf("F-") == 0) {
            return "";
        } // should not happen folders cannot have traces - no prob in any case

        let text = ""; // text to return

        let linksIterator = PrintProcessor.getItemIterator(LinksIterator.uid);
        let links = linksIterator
            ? await linksIterator.iterate(
                  overwrites,
                  { direction: params.direction },
                  itemOrFolderRef,
                  mf,
                  globals,
                  possibleTargets,
                  onError,
              )
            : [];

        // this is a item CAT-ID so below will give the item id
        let category = itemOrFolderRef.split("-")[0];

        let traceJson = <ITraceConfig>PrintProcessor.getJsonConfig("trace_config", mf);
        if (!traceJson) {
            // no trace rules defined: no problems
            return "";
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let catRules = traceJson.rules.filter((rule) => rule.category == category);
        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (catRules.length == 0) {
            // no trace rules defined: no problems
            return "";
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        let rules = params.direction == "down" ? catRules[0].down_rules : catRules[0].up_rules;

        if (params.mustHave) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            for (let rule of rules.filter((rule) => rule.rule == "must_have")) {
                if (!this.hasLinkTo(links, rule.any_of)) {
                    text += params.before + rule.message + params.after;
                }
            }
        }
        if (params.canHave) {
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            for (let rule of rules.filter((rule) => rule.rule == "can_have")) {
                if (!this.hasLinkTo(links, rule.any_of)) {
                    text += params.before + rule.message + params.after;
                }
            }
        }

        return `<span class='${params.class}'>${text}</span>`;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    private hasLinkTo(links: string[], anyOfs: string[]) {
        for (let link of links) {
            for (let anyOf of anyOfs) {
                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (link.indexOf(anyOf + "-") == 0) {
                    return true;
                }
            }
        }
        return false;
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    editParams(params: ITraceRulesEvaluationParams, onUpdate: (newParams: ITraceRulesEvaluationParams) => void) {
        let ui = $("<div>");

        let org = <ITraceRulesEvaluationParams>ml.JSON.clone({ ...this.paramsDefaults, ...params });

        const styleOpt = [
            { id: "down", label: "down traces" },
            { id: "up", label: "up traces" },
        ];
        ml.UI.addDropdownToValue(ui, "Trace direction", org, "direction", styleOpt, false, false, () => {
            onUpdate(org);
        });

        ml.UI.addCheckbox(ui, "Show errors about missing required traces", org, "mustHave", () => {
            onUpdate(org);
        });
        ml.UI.addCheckbox(ui, "Show a message about not existing optional traces", org, "canHave", () => {
            onUpdate(org);
        });
        ml.UI.addTextInput(ui, "Prefix for message", org, "before", () => {
            onUpdate(org);
        });
        ml.UI.addTextInput(ui, "Postfix for message", org, "after", () => {
            onUpdate(org);
        });

        return ui;
    }
}

PrintProcessor.addFunction(TraceRulesEvaluation.uid, new TraceRulesEvaluation());
