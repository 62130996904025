import { ml } from "../../../../core/common/matrixlib";
import { IPrintFunction, IGlobalPrintFunctionParams } from "../../../../core/printinterface/PrintFunction";
import { IPrintGlobals, IPrintProcessor } from "../../../../core/printinterface/PrintProcessorInterfaces";
import { FieldIterator } from "../../iterators/FieldIterator";
import { PrintProcessor } from "../../PrintProcessor";
import { ITableSummaryParams } from "./TableSummary";

export type { ICustomCountTableRowsParams };
export { CustomCountTableRows };

interface ICustomCountTableRowsParams {
    fieldName: string; // Name of the field with the table
    showRowsMatching: { [key: string]: string }; // The columns to filter with the given regex. Only returns rows where the regexes match
}

class CustomCountTableRows implements IPrintFunction {
    static uid = "count_table_rows";

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getGroup() {
        return "Item";
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getHelp() {
        return `<h1>Counts rows of a table</h1>
<p>Count the rows of a given table field, filtering by given regex if wanted. This
can be used with test tables to count test results with a certain value.
</p>
<p>Options</p>
<pre>
{
    fieldName:string // Name of the field with the table
    showRowsMatching:{[string]:string} // The columns to filter with the given regex. Only returns rows where the regexes match
}
</pre>`;
    }
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    getName() {
        return "Count rows of table";
    }

    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    // TODO: MATRIX-7555: lint errors should be fixed for next line
    // eslint-disable-next-line
    async renderAsync(
        overwrites: IGlobalPrintFunctionParams,
        callerParams: ITableSummaryParams,
        itemOrFolder: string,
        item: JQuery,
        mf: JQuery,
        globals: IPrintGlobals,
        possibleTargets: string[],
        onError: (message: string) => void,
        _printProcessor?: IPrintProcessor,
    ) {
        const defaults: ICustomCountTableRowsParams = {
            fieldName: "Test Case Steps",
            showRowsMatching: {},
        };

        const params = ml.JSON.clone({
            ...defaults,
            ...overwrites.customer[CustomCountTableRows.uid],
            ...callerParams,
            ...overwrites.project[CustomCountTableRows.uid],
            ...overwrites.section[CustomCountTableRows.uid],
        });

        // get the table
        let iterator = PrintProcessor.getFieldIterator(FieldIterator.uid);
        if (!iterator) {
            console.error("Can't find field iterator");
            return "";
        }

        let fields = await iterator.iterate(
            overwrites,
            { showNames: [params.fieldName] },
            itemOrFolder,
            mf,
            globals,
            possibleTargets,
            onError,
        );

        let children: string[] = [];
        if (fields && fields.length) {
            const category = ml.Item.parseRef(itemOrFolder).type;
            const xtcColumns = $(`setting_xml[key='xtc_config'] render ${category} columns`, mf);
            const tableColumns = $(`field_def[field_id=${fields[0].fieldId}] param_xml columns`, mf);
            const columnDefinitions = tableColumns.length ? tableColumns : xtcColumns;

            let table = fields[0].jsonValue;
            if (table) {
                for (let row = 0; row < table.length; row++) {
                    let hide = false;
                    $.each(columnDefinitions, function (colIdx, colDef) {
                        let colName = $(`field`, colDef).text();
                        let colVal = table[row][colName];
                        if (!colVal) {
                            colVal = "";
                        }
                        // TODO: MATRIX-7555: lint errors should be fixed for next line
                        // eslint-disable-next-line
                        if (params.showRowsMatching && params.showRowsMatching[colName] != null) {
                            let regex = new RegExp(params.showRowsMatching[colName]);
                            if (!regex.test(colVal)) {
                                hide = true;
                            }
                        }
                    });
                    if (!hide) {
                        children.push("" + row);
                    }
                }
            }
        }

        return `${children.length}`;
    }
}

PrintProcessor.addFunction(CustomCountTableRows.uid, new CustomCountTableRows());
