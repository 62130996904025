import { IPrintFunctionParams } from "../../../core/printinterface/PrintFunction";
import { PostProcessor } from "../PrintProcessorRegistry";

interface IReplaceCellsParams extends IPrintFunctionParams {
    column?: "any" | number;
    pattern?: string;
    with?: string;
    startRow?: number;
    class?: string;
    style?: string;
}

// TODO: MATRIX-7555: lint errors should be fixed for next line
// eslint-disable-next-line
function doReplace(tds: JQuery, colNumber: number, params: IReplaceCellsParams) {
    if (typeof params["pattern"] === "undefined") {
        return;
    }

    if (tds.length > colNumber) {
        let html = tds[colNumber].innerHTML;

        const className = params["class"] ? params["class"] : "";
        const style = params["style"] ? params["style"] : "";
        const withValue = `<span class='${className}' style='${style}'>${params["with"]}</span>`;
        html = html.replace(new RegExp(params["pattern"], "g"), withValue);

        tds[colNumber].innerHTML = html;
    } else {
        console.log(`less columns in table (${tds.length}) than expected (${colNumber})`);
    }
}

// example -> replace content of cells of a given column
// optional parameters class and style as html is escaped
export const replaceCells: PostProcessor = {
    run: (tableHtml: string, params: IReplaceCellsParams) => {
        // assume incoming can be a sequential with multiple tables inside or directly a table: wrap the html in a div
        const section = $("<div>").html(tableHtml);

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (typeof params["column"] == "undefined") {
            return "replaceCells - relative index of 'column' missing";
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (typeof params["pattern"] == "undefined") {
            return "replaceCells - 'pattern' to be replaced is missing";
        }

        // TODO: MATRIX-7555: lint errors should be fixed for next line
        // eslint-disable-next-line
        if (typeof params["with"] == "undefined") {
            return "replaceCells - replacement 'with' is missing";
        }

        // find all top level tables
        const tables = $("table", section).filter(function () {
            // @ts-ignore TODO: can we avoid using this here? what is it suppose to refer to?
            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            return $(this).parents("table").length == 0;
        });

        for (let table of tables.toArray()) {
            // find the first row to do the stuff
            let startRow = $("thead", $(table)).length ? 0 : 1; // if there is a header row start with the first body row, else assume that the first row in the body is a header

            // TODO: MATRIX-7555: lint errors should be fixed for next line
            // eslint-disable-next-line
            if (typeof params["startRow"] != "undefined") {
                // allow user to overwrite
                startRow = params["startRow"];
            }

            // iterate over all rows in the body and do the thing
            const rows = $(table).children("tbody").children("tr");
            for (let row = startRow; row < rows.length; row++) {
                const tds = $("td", rows[row]);

                // TODO: MATRIX-7555: lint errors should be fixed for next line
                // eslint-disable-next-line
                if (params["column"] == "any") {
                    for (let col = 0; col < tds.length; col++) {
                        doReplace(tds, col, params);
                    }
                } else {
                    let colNumber = params["column"];

                    if (colNumber < 0) {
                        colNumber = tds.length + colNumber;
                    }

                    doReplace(tds, colNumber, params);
                }
            }
        }

        return section.html();
    },
};
